import {ReactLeafletSearch} from "react-leaflet-search";
import {withLeaflet} from "react-leaflet";

class SearchControl extends ReactLeafletSearch {
    latLngHandler(latLng, info, raw) {
        const {onLocationSelect} = this.props;
        if (onLocationSelect != null) {
            onLocationSelect(latLng, info);
        } else {
            super.latLngHandler(latLng, info, raw);
        }
    }

}

export default withLeaflet(SearchControl);
import React from "react";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {CardActions, CircularProgress, MuiThemeProvider, Snackbar} from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {lightTheme} from "../layout/Layout";
import {translate} from "react-admin";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import Button from "@material-ui/core/Button";
import DeliverOrderView from "../products/DeliverOrderView";
import SendIcon from '@material-ui/icons/LocalShipping';
import RefreshIcon from '@material-ui/icons/Refresh';
import {ShowReceiptView} from "./ShowReceiptView";
import OrderTable from "./OrderTable";
import {OrderConfirmDialog} from "./OrderConfirmDialog";

const styles = {
    titleLink: {textDecoration: 'none', color: '#000'},
    card: {flex: 1, margin: '1em', height: 500, borderWidth: 0},
    progressContainer: {display: "table", marginLeft: "auto", marginRight: "auto"},
    icon: {float: 'right', width: 64, height: 64, padding: 16, color: '#a43ddb'},
};

class PendingOrder extends React.Component {
    state = {open: false, loading: false, checked: [], receiptOpen: false, confirmOrder: -1};

    componentDidMount() {
        this.loadData();
    }

    setChecked = value => {
        this.setState({checked: value});
    }

    loadData = () => {
        this.setState({loading: true, checked: []});
        fetchJson(`${SERVER_IP}/orders/getPending`)
            .then(res => {
                return JSON.parse(res.body)
            })
            .then(data => {
                this.setState({orders: data, loading: false})
            })
            .catch(e => {
                this.setState({loading: false})
                console.log(e);
            })
    }


    render() {
        const {translate} = this.props;
        const {
            orders, showMessage,
            open, message, loading, checked,
            receiptOpen, orderId, confirmOrder,
        } = this.state;
        return <Card style={styles.card} variant={'outlined'}>
            <CardHeader
                action={<CardActions>
                    <Button variant={'text'}
                            color={'primary'}
                            startIcon={<SendIcon/>}
                            onClick={() => this.setState({open: true})}
                            disabled={checked.length === 0}>
                        {translate("orders.deliverOrder.title")}
                    </Button>
                    <Button startIcon={<RefreshIcon/>}
                            disabled={loading}
                            onClick={() => this.loadData()}
                            color={'secondary'}>
                        Refresh
                    </Button>
                </CardActions>}
                title={translate("dashboard.pendingOrder.title")}/>
            {loading ? <div style={styles.progressContainer}>
                    <CircularProgress size={50}/>
                </div> :
                (orders !== undefined && Array.isArray(orders) && orders.length > 0) ?
                    <OrderTable rows={orders}
                                selectionModel={checked}
                                onConfirmClick={id => this.setState({confirmOrder: id})}

                                setSelectionModel={this.setChecked}
                                onReceiptClick={id => this.setState({receiptOpen: true, orderId: id})}/> :
                    <div style={{textAlign: 'center'}}>{translate("dashboard.pendingOrder.noData")}</div>
            }
            <DeliverOrderView open={open}
                              onResponse={(message, open) => {
                                  this.loadData();
                                  this.setState({showMessage: message !== null, message: message, open: open})
                              }}
                              record={checked}/>
            <ShowReceiptView open={receiptOpen}
                             orderId={orderId}
                             onClose={() => this.setState({receiptOpen: false})}/>
            <Snackbar open={showMessage}
                      message={message}
                      autoHideDuration={5000}
                      onClose={() => {
                          this.setState({showMessage: false})
                      }}/>
            <OrderConfirmDialog id={confirmOrder}
                                open={confirmOrder > -1}
                                onClose={(status, message) => {
                                    if (status) {
                                        this.loadData()
                                    }
                                    this.setState({showMessage: message !== null, message: message, confirmOrder: -1})
                                }}/>
        </Card>
    }
}


export const PendingOrderTranslations = {
    en: {
        dashboard: {
            pendingOrder: {
                title: "Pending Orders",
                noData: "All caught up!"
            }
        }
    },
    tr: {
        dashboard: {
            pendingOrder: {
                title: "Bekleyen Siparişler",
                noData: "Sipariş Yok!"
            }
        }
    }
}

const ThemeCard = props =>
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <PendingOrder {...props} />
    </MuiThemeProvider>;
export default translate(ThemeCard);
import React from "react";
import Icon from '@material-ui/icons/Apartment';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    FormTab,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';
import MapView from "../common/map/MapView";
import {Field} from 'react-final-form';
import {ListPagination} from "../common/Pagination";

export const DistrictIcon = Icon;

export const DistrictList = props => (
    <List {...props}
          sort={{field: 'id', order: 'DESC'}}
          pagination={<ListPagination/>}
          perPage={50}
          bulkActionButtons={false}>
        <Datagrid>
            <TextField source={'name'}/>
            <ReferenceField source={'city.id'} reference={'cities'}>
                <TextField source={'name'}/>
            </ReferenceField>
            <BooleanField source={'active'}/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const DistrictCreate = props => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const DistrictEdit = props => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CreateOrEdit = props => (
    <TabbedForm {...props} redirect={'list'}>
        <FormTab label={'resources.districts.fields.location'}>
            <Field component={MapView}
                   name={'location'}
                   editable
                   source={'location'}/>
        </FormTab>
        <FormTab label={'resources.districts.general'}>
            <TextInput source={'name'} validate={required()}/>
            <ReferenceInput source={'city.id'} perPage={1000} reference={'cities'} validate={required()}>
                <SelectInput/>
            </ReferenceInput>
            <BooleanInput source={'active'}/>
        </FormTab>
    </TabbedForm>
);

export const DistrictTranslations = {
    en: {
        districts: {
            name: "Districts",
            general: "General",
            fields: {
                name: "Name",
                location: "Location",
                active: "Active",
                city: {
                    id: "City",
                }
            }
        }
    },
    tr: {
        districts: {
            name: "İlçeler",
            general: "Genel",
            fields: {
                name: "Adı",
                location: "Konum",
                active: "Aktif",
                city: {
                    id: "Şehir",
                }
            }
        }
    }
}
import React from 'react';
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import {Filter, List, SelectInput, translate} from "react-admin";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Field} from 'react-final-form';
import "jspdf-autotable"
import {pdfExport} from "../Util";


const periods = [
    {id: "DAY", name: 'resources.orderReports.period.day'},
    {id: "WEEK", name: 'resources.orderReports.period.week'},
    {id: "MONTH", name: 'resources.orderReports.period.month'},
    {id: "YEAR", name: 'resources.orderReports.period.year'},

]
const orderExporter = items => {
    const itemsForExport = items.map(item => {
        return [displayableDate(item.date), item.received.count, item.received.price,
            item.delivered.count, item.delivered.price,
            item.delayed.count, item.delayed.price, item.confirmed.count,
            item.confirmed.price, item.cancelled.count, item.cancelled.price]
    });

    const title = "Order Report";
    let headers;
    headers = [["Date", "Received", "Received Amount", "Delivered",
        "Delivered Amount", "Delayed", "Delayed Amount",
        "Confirmed", "Confirmed Amount", "Cancelled", "Cancelled Amount"]]
    pdfExport(title, itemsForExport, headers);
};

export const DatePickerView = translate(({translate, start, label, min, max, input, disabled, justDate}) => {
    if (input === undefined) {
        return <span/>
    }
    if (input.value < 1000) {
        let date = new Date();
        if (start) {
            date.setDate(date.getDate() - 1)
        }
        input.onChange(date.getTime())
    }
    if (justDate) {
        return <MuiPickersUtilsProvider
            utils={DateFnsUtils} locale={enLocale}>
            <DatePicker
                disabled={disabled}
                value={input.value}
                maxDate={max}
                minDate={min}
                label={translate(label)}
                format={"yyyy-MM-dd"}
                onChange={(date) => {
                    input.onChange(date.getTime())
                }}
            />
        </MuiPickersUtilsProvider>
    }
    return <MuiPickersUtilsProvider
        utils={DateFnsUtils} locale={enLocale}>
        <DateTimePicker
            disabled={disabled}
            value={input.value}
            maxDate={max}
            minDate={min}
            label={translate(label)}
            ampm={false}
            format={"yyyy-MM-dd HH:mm:ss"}
            onChange={(date) => {
                input.onChange(date.getTime())
            }}
        />
    </MuiPickersUtilsProvider>
});

export const Filters = (props) => {
    let start = props.filterValues.start;
    let end = props.filterValues.end;
    let justDate = props.justDate;
    let startDate = new Date();
    let endDate = new Date();
    if (start !== undefined) {
        startDate.setTime(start);
    }
    if (end !== undefined) {
        endDate.setTime(end)
    }
    return <Filter {...props}>
        <SelectInput source={'period'} choices={periods} alwaysOn/>
        <Field component={DatePickerView}
               alwaysOn
               start
               justDate={justDate}
               source={"start"}
               name={'start'}
               label={"resources.orderReports.fields.startDate"}
               max={new Date()}/>
        <Field component={DatePickerView}
               {...props}
               alwaysOn
               justDate={justDate}
               source={"end"}
               name={'end'}
               max={new Date()}
               label={"resources.orderReports.fields.endDate"}
               min={startDate}/>
    </Filter>
};

const OrderReportGird = ({ids, data, translate}) => {
    let total = 0;
    let received = {
        total: 0,
        amount: 0
    }
    let delayed = {
        total: 0,
        amount: 0
    }
    let completed = {
        total: 0,
        amount: 0
    }
    let confirmed = {
        total: 0,
        amount: 0
    }
    let cancelled = {
        total: 0,
        amount: 0
    }

    return <Table>
        <TableHead>
            <TableRow>
                <TableCell><b>{translate("resources.orderReports.date")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.received")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.receivedAmount")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.completed")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.completedAmount")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.delayed")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.delayedAmount")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.confirmed")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.confirmedAmount")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.cancelled")}</b></TableCell>
                <TableCell><b>{translate("resources.orderReports.cancelledAmount")}</b></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {ids.map(id => {
                    let record = data[id];
                    total += record.totalAmount;
                    received.total += record.received.count;
                    received.amount += record.received.price;
                    delayed.total += record.delayed.count;
                    delayed.amount += record.delayed.price;
                    confirmed.total += record.confirmed.count;
                    confirmed.amount += record.confirmed.price;
                    cancelled.total += record.cancelled.count;
                    cancelled.amount += record.cancelled.price;
                    completed.total += record.delivered.count;
                    completed.amount += record.delivered.price;
                    return <TableRow key={id}>
                        <TableCell>
                            <span>{displayableDate(record.date)}</span>
                        </TableCell>
                        <TableCell>
                            <span>{record.received.count}</span>
                        </TableCell>
                        <TableCell>
                            {record.received.price.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </TableCell>
                        <TableCell>
                            <span>{record.delivered.count}</span>
                        </TableCell>
                        <TableCell>
                            {record.delivered.price.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </TableCell>
                        <TableCell>
                            <span>{record.delayed.count}</span>
                        </TableCell>
                        <TableCell>
                            {record.delayed.price.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </TableCell>
                        <TableCell>
                            <span>{record.confirmed.count}</span>
                        </TableCell>
                        <TableCell>
                            {record.confirmed.price.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </TableCell>
                        <TableCell>
                            <span>{record.cancelled.count}</span>
                        </TableCell>
                        <TableCell>
                            {record.cancelled.price.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'TRY',
                            })}
                        </TableCell>
                    </TableRow>
                }
            )}
            <TableRow key={"total"}>
                <TableCell>
                    <b>{translate("resources.orderReports.total")}</b>
                </TableCell>
                <TableCell><b>{received.total}</b></TableCell>
                <TableCell><b>{received.amount.toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'TRY'
                })}</b></TableCell>
                <TableCell><b>{completed.total}</b></TableCell>
                <TableCell><b>{completed.amount.toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'TRY'
                })}</b>
                </TableCell>
                <TableCell><b>{delayed.total}</b></TableCell>
                <TableCell><b>{delayed.amount.toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'TRY'
                })}</b>
                </TableCell>
                <TableCell><b>{confirmed.total}</b></TableCell>
                <TableCell><b>{confirmed.amount.toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'TRY'
                })}</b>
                </TableCell>
                <TableCell><b>{cancelled.total}</b></TableCell>
                <TableCell><b>{cancelled.amount.toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'TRY'
                })}</b>
                </TableCell>

                <TableCell>
                    <b>{total.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'TRY',
                    })}</b>
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
};
const ReportGrid = translate(OrderReportGird);
const displayableDate = range => {
    if ((range.end - range.start) > 86400000) {
        return `${new Date(range.start).toLocaleDateString()} - ${new Date(range.end).toLocaleDateString()}`
    }
    return new Date(range.start).toLocaleDateString()
}
export const ListOrderReport = (props) => (
    <List {...props}
          exporter={orderExporter}
          filterDefaultValues={{period: "DAY", start: new Date().getTime(), end: new Date().getTime()}}
          filters={<Filters justDate={true}/>} bulkActionButtons={false}
          pagination={false}>
        <ReportGrid/>
    </List>
);

export const OrderReportTranslations = {
    en: {
        orderReports: {
            name: "Order Report",
            total: "Total",
            date: "Date",
            received: "Received",
            receivedAmount: "Received Amount",
            completed: "Delivered",
            completedAmount: "Delivered Amount",
            confirmed: "Confirmed",
            confirmedAmount: "Confirmed Amount",
            delayed: "Delayed",
            delayedAmount: "Delayed Amount",
            cancelled: "Cancelled",
            cancelledAmount: "Cancelled Amount",
            fields: {
                endDate: "End",
                startDate: "Start",
            },
            period: {
                day: "DAY",
                month: "Month",
                week: "Week",
                year: "Year",
            }
        }
    },
    tr: {
        orderReports: {
            name: "Satış Raporu",
            total: "Toplam",
            date: "Tarih",
            received: "Received",
            receivedAmount: "Received Amount",
            completed: "Teslim Edildi",
            completedAmount: "Delivered Amount",
            confirmed: "Confirmed",
            confirmedAmount: "Confirmed Amount",
            delayed: "Delayed",
            delayedAmount: "Delayed Amount",
            cancelled: "Cancelled",
            cancelledAmount: "Cancelled Amount",
            fields: {
                endDate: "End",
                startDate: "Start",
            },
            period: {
                day: "DAY",
                month: "Month",
                week: "Week",
                year: "Year",
            }
        }
    }
}
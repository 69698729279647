import React from "react";
import Icon from '@material-ui/icons/House';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    FormTab,
    List,
    maxLength,
    minLength,
    minValue,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';
import MapView from "../common/map/MapView";
import {Field} from 'react-final-form';
import {ListPagination} from "../common/Pagination";

export const TownIcon = Icon;

export const TownList = props => (
    <List {...props}
          sort={{field: 'id', order: 'DESC'}}
          perPage={50}
          pagination={<ListPagination/>}
          bulkActionButtons={false}>
        <Datagrid>
            <TextField source={'name'}/>
            <TextField source={'zip'}/>
            <ReferenceField source={'district.id'} reference={'districts'}>
                <TextField source={'name'}/>
            </ReferenceField>
            <TextField source={"minimumOrder"}/>
            <TextField source={"deliveryFee"}/>
            <TextField source={"freeDeliveryLimit"}/>
            <BooleanField source={'deliveryFree'}/>
            <TextField source={'minimumDeliveryMinutes'}/>
            <TextField source={'maximumDeliveryMinutes'}/>
            <BooleanField source={'active'}/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const TownCreate = props => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const TownEdit = props => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CreateOrEdit = props => (
    <TabbedForm {...props} redirect={'list'}>
        <FormTab label={'resources.towns.fields.location'}>
            <Field component={MapView}
                   name={'location'}
                   editable
                   source={'location'}/>
        </FormTab>
        <FormTab label={'resources.towns.general'}>
            <TextInput source={'name'} validate={required()}/>
            <NumberInput source={'zip'} validate={[required(), minLength(3), maxLength(7)]}/>
            <ReferenceInput perPage={1000} source={'district.id'} reference={'districts'} validate={required()}>
                <SelectInput/>
            </ReferenceInput>
            <NumberInput source={"deliveryFee"}/>
            <NumberInput source={"deliveryFeeTier1"}/>
            <NumberInput source={"deliveryFeeTier2"}/>
            <NumberInput source={"freeDeliveryLimit"}/>
            <NumberInput source={"minimumOrder"} validate={[required(), minValue(1)]}/>
            <BooleanInput source={'deliveryFree'}/>
            <NumberInput source={'minimumDeliveryMinutes'}/>
            <NumberInput source={'maximumDeliveryMinutes'}/>
            <BooleanInput source={'active'}/>
        </FormTab>
    </TabbedForm>
);

export const TownTranslations = {
    en: {
        towns: {
            name: "Towns",
            general: "General",
            fields: {
                name: "Name",
                location: "Location",
                active: "Active",
                deliveryFree: "Delivery Free",
                deliveryFee: "Delivery Fee > 750",
                deliveryFeeTier1: "Delivery Fee for 200-750",
                deliveryFeeTier2: "Delivery Fee for < 200",
                freeDeliveryLimit: "Free Delivery Limit",
                district: {
                    id: "District",
                }
            }
        }
    },
    tr: {
        towns: {
            name: "Mahalle",
            general: "Genel",
            fields: {
                name: "Adı",
                location: "Konum",
                active: "Aktif",
                deliveryFree: "Bedava Teslimat?",
                deliveryFee: ">750 için Teslimat Ücreti",
                deliveryFeeTier1: "200-750 için Teslimat Ücreti",
                deliveryFeeTier2: "< 200 için Teslimat Ücreti",
                freeDeliveryLimit: "Bedava teslimat ücreti alışveriş limiti",
                district: {
                    id: "İlçe",
                }
            }
        }
    }
}
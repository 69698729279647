import englishMessages from 'ra-language-english';
import {ImageUploadTranslation} from "../common/ImageUpload";
import {AddressTranslations, UserTranslations} from "../user/User";
import {CategoryTranslations} from "../categories/Category";
import {UserResetTranslations} from "../user/ResetDeviceView";
import {DepartmentTranslations} from "../categories/Department";
import {MenuTranslations} from "../layout/Menu";
import {CityTranslations} from "../address/City";
import {DistrictTranslations} from "../address/District";
import {TownTranslations} from "../address/Town";
import {DriverTranslations} from "../user/Driver";
import {ProductTranslations} from "../products/Product";
import {CampaignTranslations} from "../products/Campaign";
import {PendingOrderTranslations} from "../dashboard/PendingOrder";
import {OrderTranslations} from "../products/Order";
import {OrderReportTranslations} from "../report/OrderReport";
import {NotificationEmailTranslations} from "../settings/NotificationEmail";
import {NotificationTranslations} from "../notification/Notification";
import {ConfigurationStrings} from "../settings/Configuration";
import {DeliverOrderTranslations} from "../products/DeliverOrderView";
import {MarkOutOfStockTranslations} from "../products/MarkOutOfStockView";
import {ProductsListActionTranslations} from "../products/ProductsListBulkActions";
import {SaleTranslations} from "../sale/SaleList";
import {RefundTranslations} from "../sale/RefundList";
import {SaleReportTranslations} from "../report/ProductSaleReport";
import {DepoSaleUserTranslations, SaleUserTranslations, ShopSaleUserTranslations} from "../user/SaleUser";
import {FaqTranslations} from "../Faq";
import {DepartmentOrderTranslations} from "../categories/DepartmentReorder";
import {SaleCreateTranslations} from "../sale/SaleCreate";
import {ProductOrderTranslations} from "../products/ProductReorder";
import {CategoryOrderTranslations} from "../categories/CategoryReorder";
import {OrderUpdateTranslations} from "../products/UpdateOrder";

export default {
    ...englishMessages,
    ...ImageUploadTranslation.en,
    login: {
        loginFailed: "Login failed. Check username or password"
    },

    resources: {
        ...UserTranslations.en,
        ...CategoryTranslations.en,
        ...DepartmentTranslations.en,
        ...CityTranslations.en,
        ...DistrictTranslations.en,
        ...TownTranslations.en,
        ...DriverTranslations.en,
        ...ProductTranslations.en,
        ...CampaignTranslations.en,
        ...OrderTranslations.en,
        ...OrderReportTranslations.en,
        ...AddressTranslations.en,
        ...NotificationEmailTranslations.en,
        ...NotificationTranslations.en,
        ...ConfigurationStrings.en,
        ...SaleTranslations.en,
        ...RefundTranslations.en,
        ...SaleReportTranslations.en,
        ...DepoSaleUserTranslations.en,
        ...SaleUserTranslations.en,
        ...ShopSaleUserTranslations.en,
        ...FaqTranslations.en,
    },
    ...UserResetTranslations.en,
    ...MenuTranslations.en,
    ...PendingOrderTranslations.en,
    ...DeliverOrderTranslations.en,
    ...MarkOutOfStockTranslations.en,
    ...ProductsListActionTranslations.en,
    ...SaleCreateTranslations.en,
    ...DepartmentOrderTranslations.en,
    ...ProductOrderTranslations.en,
    ...CategoryOrderTranslations.en,
    ...OrderUpdateTranslations.en,
}
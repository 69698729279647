import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';

import {DashboardMenuItem, MenuItemLink, Responsive, translate, WithPermissions} from 'react-admin';
import UserIcon from '@material-ui/icons/AccountCircle';
import {CategoryIcon} from "../categories/Category";
import SubMenu from "./SubMenu";
import {DepartmentIcon} from "../categories/Department";
import {CityIcon} from "../address/City";
import LocationIcon from '@material-ui/icons/LocationOn';
import {DistrictIcon} from "../address/District";
import {TownIcon} from "../address/Town";
import DriverIcon from '@material-ui/icons/LocalTaxi';
import {ProductIcon} from "../products/Product";
import GroceryIcon from '@material-ui/icons/LocalGroceryStore';
import {CampaignIcon} from "../products/Campaign";
import {OrderIcon} from "../products/Order";
import ReportIcon from '@material-ui/icons/Assessment';
import EmailIcon from '@material-ui/icons/Email';
import OtherIcon from '@material-ui/icons/Settings';
import NotificationIcon from '@material-ui/icons/Notifications';
import ConfigIcon from '@material-ui/icons/Style';
import DepoSaleIcon from '@material-ui/icons/Store';
import SaleIcon from '@material-ui/icons/ShoppingCart';
import RefundIcon from '@material-ui/icons/MoneyOff';
import SalePersonIcon from '@material-ui/icons/PersonPin'
import {FaqIcon} from "../Faq";

const categories = [{name: 'departments', icon: <DepartmentIcon/>},
    {name: 'categories', icon: <CategoryIcon/>}]
const locations = [{name: 'cities', icon: <CityIcon/>},
    {name: 'districts', icon: <DistrictIcon/>}, {name: "towns", icon: <TownIcon/>}]
const users = [{name: 'customers', icon: <UserIcon/>},
    {name: "drivers", icon: <DriverIcon/>},
    {name: "salePersons", icon: <SalePersonIcon/>},
    {name: "depoSalePersons", icon: <DepoSaleIcon/>},
    {name: "shopSalePersons", icon: <SaleIcon/>}]
const products = [{name: 'products', icon: <ProductIcon/>},
    {name: 'campaigns', icon: <CampaignIcon/>}, {name: "orders", icon: <OrderIcon/>}]
const reports = [{name: "orderReports", icon: <OrderIcon/>}, {name: "saleReports/shop", icon: <SaleIcon/>},
    {name: "saleReports/depo", icon: <DepoSaleIcon/>}]
const others = [{name: "notificationEmails", icon: <EmailIcon/>},
    {name: "notifications", icon: <NotificationIcon/>}, {name: "appConfigs", icon: <ConfigIcon/>}, {
        name: "faq",
        icon: <FaqIcon/>
    }]
const refunds = [{name: "refunds", icon: <SaleIcon/>}, {name: "depoRefunds", icon: <DepoSaleIcon/>}]

const sales = [{name: "sales", icon: <SaleIcon/>}, {name: "depoSales", icon: <DepoSaleIcon/>}]
const commons = [{label: 'resources.products.name', ...products[0]}, {label: "resources.orders.name", ...products[2]}, {label: "resources.customers.name", ...users[0]}]
const salePersonItems = [
    {label: 'menu.sales', icon: <DepoSaleIcon/>, items: sales, key: 'sales'},
    {label: 'menu.refunds', icon: <RefundIcon/>, items: refunds, key: 'refunds'},
    ...commons
]

const depoSalePersonItems = [{label: "resources.depoSales.name", ...sales[1]},
    ...commons,
]
const shopSalePersonItems = [{label: "resources.sales.name", ...sales[0]},
    ...commons,]

const adminItems = [
    {label: 'menu.locations', items: locations, key: 'locations', icon: <LocationIcon/>},
    {label: 'resources.categories.name', items: categories, key: 'categories', icon: <DepartmentIcon/>},
    {label: 'menu.products', items: products, key: 'products', icon: <GroceryIcon/>},
    {label: 'menu.users', icon: <UserIcon/>, items: users, key: "users"},
    {label: 'menu.others', icon: <OtherIcon/>, items: others, key: 'others'},
    {label: 'menu.sales', icon: <DepoSaleIcon/>, items: sales, key: 'sales'},
    {label: 'menu.refunds', icon: <RefundIcon/>, items: refunds, key: 'refunds'},
    {label: 'menu.reports', icon: <ReportIcon/>, items: reports, key: 'reports'},
];

class Menu extends Component {
    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };
    state = {};

    handleToggle = menu => {
        this.setState(state => ({[menu]: !state[menu]}));
    };

    render() {
        const {onMenuClick, logout, translate, permissions} = this.props;
        let items = []
        if (permissions === "ROLE_ADMIN") {
            items = adminItems;
        } else if (permissions === "ROLE_DEPO_SALE_PERSON") {
            items = depoSalePersonItems;
        } else if (permissions === "ROLE_SALE_PERSON") {
            items = salePersonItems;
        } else if (permissions === "ROLE_SHOP_SALE_PERSON") {
            items = shopSalePersonItems;
        } else {
            return <div/>
        }
        return <div>
            {<DashboardMenuItem onClick={onMenuClick}/>}
            {items.map(item => {
                if (item.items !== undefined) {
                    return <SubMenu isOpen={this.state[item.key]}
                                    key={item.key}
                                    icon={item.icon}
                                    sidebarIsOpen={true}
                                    handleToggle={() => this.handleToggle(item.key)}
                                    name={item.label}>
                        {item.items.map(subItem => (
                            <MenuItemLink
                                key={subItem.name}
                                to={`/${subItem.name}`}
                                primaryText={translate(`resources.${subItem.name}.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={subItem.icon}
                                onClick={onMenuClick}
                            />
                        ))}
                    </SubMenu>
                }
                return <MenuItemLink
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={translate(`resources.${item.name}.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                />
            })}
            <Responsive
                small={logout}
                medium={null}
            />
        </div>
    }
}


const mapStateToProps = state => {
    return {
        theme: state.theme,
    }
};

export const MenuTranslations = {
    en: {
        menu: {
            locations: "Locations",
            users: "Users",
            products: "Items",
            reports: "Reports",
            others: "Others",
            sales: "Ventes",
            refunds: "Retours",
        }
    },
    tr: {
        menu: {
            locations: "Bölgeler",
            users: "Kullanıcılar",
            products: "Ürünler",
            reports: "Raporlar",
            others: "Diğer",
            sales: "Satış",
            refunds: "Iade",
        }
    }
}

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);
const EnhancedMenu = enhance(Menu);
const PermissionMenu = (props) => (
    <WithPermissions render={({permissions}) => <EnhancedMenu {...props}
                                                              permissions={permissions}/>}
                     {...props}/>
);
export default PermissionMenu;
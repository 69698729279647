import React from "react";
import Icon from '@material-ui/icons/LocationCity';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    FormTab,
    List,
    required,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin';
import MapView from "../common/map/MapView";
import {Field} from 'react-final-form';
import {ListPagination} from "../common/Pagination";

export const CityIcon = Icon;

export const CityList = (props) => (
    <List {...props}
          sort={{field: 'id', order: 'DESC'}}
          pagination={<ListPagination/>}
          perPage={50}
          bulkActionButtons={false}>
        <Datagrid>
            <TextField source={'name'}/>
            <BooleanField source={'active'}/>
            <EditButton/>
        </Datagrid>
    </List>
);
export const CityCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>

);
export const CityEdit = props => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CreateOrEdit = props => (
    <TabbedForm {...props} redirect={'list'}>
        <FormTab label={'resources.cities.fields.location'}>
            <Field component={MapView}
                   name={'location'}
                   editable
                   source={'location'}/>
        </FormTab>
        <FormTab label={'resources.cities.general'}>
            <TextInput source={'name'} validate={required()}/>
            <BooleanInput source={'active'}/>
        </FormTab>

    </TabbedForm>
);

export const CityTranslations = {
    tr: {
        cities: {
            name: "Şehirler",
            general: "Genel",
            fields: {
                name: "Adı",
                location: "Hizmet Bölgeleri",
                active: "Aktif",
            }
        }
    },
    en: {
        cities: {
            name: "Şehirler",
            general: "Genel",
            fields: {
                name: "İsim",
                location: "Hizmet Bölgeleri",
                active: "Aktif",
            }
        }
    }
}
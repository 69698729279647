import {connect} from 'react-redux';
import {Layout} from 'react-admin';
import React from "react";
import Menu from './Menu';

export const lightTheme = {
    palette: {
        secondary: {
            light: '#73ff65',
            main: '#32cd32',
            dark: '#009b00',
            contrastText: '#fff',
        },
        primary: {
            light: '#63a4ff',
            main: '#1976d2',
            dark: '#004ba0',
            contrastText: '#fff',
        },
    },
};

const CustomLayout = props => (
    <Layout {...props}
            menu={Menu}/>
);
export default connect(
    state => ({
        theme: lightTheme,
    }),
    {}
)(CustomLayout);
import React from "react";
import SendIcon from '@material-ui/icons/LocalShipping';
import {Button, createMuiTheme, DialogActions, MuiThemeProvider, Snackbar} from "@material-ui/core";
import {translate} from 'react-admin';
import {lightTheme} from "../layout/Layout";
import DeliverOrderView from "./DeliverOrderView";

class UserShowActions extends React.Component {
    state = {open: false};

    render() {
        const {data, translate} = this.props;
        const {showMessage, message, open} = this.state;
        return (
            <DialogActions>
                {data !== undefined && <Button variant={'text'}
                                               color={'primary'}
                                               disabled={data.status !== undefined && data.status === "DELIVERED"}
                                               onClick={() => this.setState({open: true})}>
                    <SendIcon/> {translate("orders.deliverOrder.title")}
                </Button>}
                <DeliverOrderView open={open}
                                  onResponse={(message, open) => {
                                      this.setState({showMessage: message !== null, message: message, open: open})
                                  }}
                                  record={data}/>
                <Snackbar open={showMessage}
                          message={message}
                          autoHideDuration={5000}
                          onClose={() => {
                              this.setState({showMessage: false})
                          }}/>
            </DialogActions>
        );
    }
}

const Themed = props =>
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <UserShowActions {...props}/>
    </MuiThemeProvider>;
export default translate(Themed);
import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    Card,
    CardActions,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    MuiThemeProvider,
    Snackbar,
    TextField
} from "@material-ui/core";
import {Title, useTranslate} from "react-admin";
import CardContent from "@material-ui/core/CardContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {SERVER_IP} from "../index";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import {createMuiTheme} from "@material-ui/core/styles";
import {lightTheme} from "../layout/Layout";
import DeleteIcon from '@material-ui/icons/Delete';
import {isNumber} from "../Util";
import {fetchJson} from "../rest/fetch";
import {retina} from "leaflet/src/core/Browser";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 10,
        userSelect: 'none',
        borderRadius: 10,
    },
    table: {
        minWidth: 650,
    },
    saleButton: {
        width: '10em',
        borderRadius: '2em',
        marginLeft: "auto",
    },
    anotherButton: {
        marginLeft: 0,
        marginRight: '10em',
    },
    deleteButton: {
        color: 'red'
    },
    bigMoney: {
        fontWeight: "bold",
        fontSize: '20px'
    }
}));

const defaultObj = () => ({id: -1, barcode: "", name: "", itemPrice: 0, quantity: 1, stock: ""})

function ccyFormat(num, allowNegative) {
    if (isNumber(num) && allowNegative) {
        return `${num.toFixed(2)}`;
    }
    return num <= 0 ? "" : `${num.toFixed(2)}`;
}


function priceRow(qty, unit) {
    return ccyFormat(qty * unit);
}

function OrderUpdate({id}) {
    const classes = useStyles();
    const [rows, setRows] = useState([defaultObj()])
    const [change, setChange] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [discount, setDiscount] = useState(-1)
    const [success, setSuccess] = useState(-1)
    const [delivery, setDelivery] = useState(-1)
    const translate = useTranslate()

    useEffect(() => {
        setLoading(true);
        fetchJson(`${SERVER_IP}/orders/get/${id}`)
            .then(({json}) => {
                const rows = json.items.map(item => {
                    return {
                        id: item.product.id,
                        itemPrice: item.usedPrice,
                        quantity: item.quantity,
                        stock: item.product.stock,
                        barcode: item.product.barcode,
                        name: item.product.name.english
                    }
                })
                setRows(rows)
                setDiscount(calculateDiscountPercentage(json.total,json.creditUsed))
                setDelivery(json.deliveryFee)
                setLoading(false)
            }).catch(e => {
            setMessage(e)
            console.log(e)
            setLoading(false)
        })
    }, [id])
    const onChange = (index, name, event) => {
        let row = rows[index]
        let value = event.target.value
        if (name === 'quantity') {
            if (value.length > 0 && !isNumber(value)) {
                return
            }
            if (Number(value) > row.stock) {
                return;
            }
        }
        row[name] = value
        rows[index] = row
        setRows(rows)
        setChange(!change)
    }


    const onDiscountChange = event => {
        let value = Number(event.target.value)
        if (isNaN(value)) {
            return
        }
        setDiscount(value)
    }

    const onDeliveryChange = event => {
        let value = Number(event.target.value)
        if (isNaN(value)) {
            return
        }
        setDelivery(value)
    }

    const calculateDiscount = () => {
        if (discount <= 0) {
            return 0
        }
        let sub = calculateSubTotal()
        if (sub === 0) {
            return 0;
        }
       return (discount / 100) * sub
    }
    const calculateDiscountPercentage = (total,discount) => {
        if (discount <= 0) {
            return 0
        }
        return (discount / total) * 100
    }


    const calculateSubTotal = () => {
        let total = 0;
        rows.forEach(item => {
            let price = item.itemPrice
            if (item.manualPrice && item.manualPrice > 0) {
                total += Number(item.manualPrice);
            } else {
                total += item.quantity * price
            }
        })
        return total
    }

    const calculateTotal = () => {
        let d = delivery > 0 ? delivery : 0;
        return calculateSubTotal() - calculateDiscount() + d
    }

    const onKeyPress = (index, name, e) => {
        if (e.key === "Enter") {
            if (name === 'barcode' || name === "id") {
                loadProduct(index, e.target.value, name === "id")
            } else if (name === 'quantity') {
                if (rows[index].quantity > 0) {
                    addRow(index)
                    focus(`barcode${index + 1}`)
                }
            } else if (name === 'manualPrice') {
                if (rows.length - 1 === index) {
                    addRow(index)
                }
                focus(`barcode${index + 1}`)
            }
            e.preventDefault()
        }
    }
    const focus = (name, timeout = 200) => {
        setTimeout(() => {
            const nextSibling = document.querySelector(
                `input[name=${name}]`
            );
            if (nextSibling != null) {
                nextSibling.focus()
            }
        }, timeout)
    }
    const addRow = (index) => {
        if (index < rows.length - 1) {
            return;
        }
        rows.push(defaultObj())
        setRows(rows)
        setChange(!change)
    }

    const loadProduct = (index, barcode, id = false) => {
        let alreadyPresent = rows.findIndex(r => r.barcode === barcode && r.id !== -1)
        if (alreadyPresent > -1) {
            let row = rows[alreadyPresent]
            row.quantity += 1
            rows[alreadyPresent] = row
            setChange(!change)
            focus(`quantity${alreadyPresent}`)
            return
        }
        setLoading(true)
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        fetch(`${SERVER_IP}/products/${id ? "searchWithId" : "searchWithBarcode"}/${barcode}`,
            {method: "GET", headers: headers})
            .then(response => {
                return response.json();
            })
            .then((product) => {
                setLoading(false);
                setupProduct(index, product)
            })
            .catch(e => {
                setMessage(translate('orderUpdate.notFound'))
                setLoading(false);
            });
    }

    const setupProduct = (index, product) => {
        let alreadyPresent = rows.findIndex(r => r.id === product.id)
        if (alreadyPresent > -1) {
            let row = rows[alreadyPresent]
            index = alreadyPresent
            row.quantity += 1
            rows[index] = row
        } else {
            let row = rows[index]
            row.barcode = product.barcode
            row.id = product.id
            row.name = product.name.turkish
            row.stock = product.stock
            row.quantity = row.stock > 0 ? 1 : 0
            row.itemPrice = product.newPrice != null && product.newPrice > 0 ? product.newPrice : product.price
            rows[index] = row
        }
        setChange(!change)
        focus(`quantity${index}`)
    }

    const saveOrder = () => {
        let request = {
            orderId: id,
            discount: calculateDiscount(),
            items: rows.filter(item => item.id !== -1 && item.quantity > 0)
                .map(row => ({
                    id: row.id,
                    quantity: row.quantity
                }))
        }

        if (request.items.length === 0) {
            return
        }
        setLoading(true)
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

        fetch(`${SERVER_IP}/orders/updateOrder`,
            {method: "PATCH", headers: headers, body: JSON.stringify(request)})
            .then(response => {
                return response.json();
            })
            .then((s) => {
                setSuccess(s.id)
                setLoading(false);
            })
            .catch(e => {
                setMessage(translate('orderUpdate.fail'))
                setLoading(false);
            });
    }
    const onDeleteRow = (index) => {
        rows.splice(index, 1)
        setRows(rows)
        setChange(!change)
        if (rows.length === 0) {
            addRow(0)
        }
    }

    return (
        <Card>
            <Title title={translate('orderUpdate.title')}/>
            <CardHeader>
                {loading && <CircularProgress size={24}/>}
            </CardHeader>
            <CardContent>
                <Table className={classes.table} aria-label="sale table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>#</b></TableCell>
                            <TableCell align="center"><b>{translate('orderUpdate.barcode')}</b></TableCell>
                            <TableCell align="center"><b>{translate('orderUpdate.id')}</b></TableCell>
                            <TableCell align="center"><b>{translate('orderUpdate.product')}</b></TableCell>
                            <TableCell align="center"><b>{translate('orderUpdate.stock')}</b></TableCell>
                            <TableCell align="center"><b>{translate('orderUpdate.quantity')}</b></TableCell>
                            <TableCell align="right"><b>{translate('orderUpdate.itemPrice')}</b></TableCell>
                            <TableCell align="right"><b>{translate('orderUpdate.totalPrice')}</b></TableCell>
                            <TableCell align={'center'}><b>{translate('orderUpdate.action')}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>

                                <TableCell align="center">
                                    <TextField
                                        disabled={loading}
                                        onKeyPress={e => onKeyPress(index, 'barcode', e)}
                                        size={'small'}
                                        label={translate('orderUpdate.barcode')}
                                        value={row.barcode}
                                        onChange={e => onChange(index, 'barcode', e)}
                                        name={`barcode${index}`}
                                        variant={'outlined'}/>
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        disabled={loading}
                                        onKeyPress={e => onKeyPress(index, 'id', e)}
                                        size={'small'}
                                        label={translate('orderUpdate.id')}
                                        value={row.id === -1 ? "" : row.id}
                                        onChange={e => onChange(index, 'id', e)}
                                        name={`id${index}`}
                                        variant={'outlined'}/>
                                </TableCell>
                                <TableCell component="th"
                                           align={'center'}
                                           scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align={'center'}>{row.stock}</TableCell>
                                <TableCell align="center">
                                    <TextField
                                        disabled={row.name === "" || loading || row.stock === 0}
                                        onKeyPress={e => onKeyPress(index, 'quantity', e)}
                                        size={'small'}
                                        required
                                        label={translate('orderUpdate.quantity')}
                                        inputMode={'numeric'}
                                        name={`quantity${index}`}
                                        onChange={e => onChange(index, 'quantity', e)}
                                        value={row.quantity}
                                        variant={'outlined'}/>
                                </TableCell>
                                <TableCell align="right">{ccyFormat(row.itemPrice)}</TableCell>

                                <TableCell
                                    align="right">{priceRow(row.quantity, row.itemPrice)}</TableCell>
                                <TableCell align={"center"}>
                                    {row.name !== "" &&
                                        <Button variant={"text"}
                                                startIcon={<DeleteIcon/>}
                                                className={classes.deleteButton}
                                                onClick={() => onDeleteRow(index)}
                                                color={'primary'}>
                                            {translate('orderUpdate.delete')}
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell rowSpan={7} colSpan={7}/>
                            <TableCell colSpan={2}><b>{translate('orderUpdate.subtotal')}</b></TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(calculateSubTotal())}</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><b>{translate('orderUpdate.disc')}</b></TableCell>
                            <TableCell align="center">
                                <TextField
                                    disabled={loading}
                                    size={'small'}
                                    label={translate('orderUpdate.discount')}
                                    inputMode={'numeric'}
                                    name={`discount`}
                                    required
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    onChange={onDiscountChange}
                                    value={discount === -1 ? "" : discount}
                                    variant={'outlined'}/>
                            </TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(calculateDiscount())}</b></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell><b>{translate('orderUpdate.deliveryFee')}</b></TableCell>
                            <TableCell align="center">
                                <TextField
                                    disabled
                                    size={'small'}
                                    required
                                    label={translate('orderUpdate.deliveryFee')}
                                    inputMode={'numeric'}
                                    name={`deliveryFee`}
                                    onChange={onDeliveryChange}
                                    value={delivery === -1 ? "" : delivery}
                                    variant={'outlined'}/>
                            </TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(delivery)}</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}><b>{translate('orderUpdate.total')}</b></TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(calculateTotal())}</b></TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </CardContent>
            <Snackbar
                onClose={() => setMessage("")}
                autoHideDuration={3000}
                message={message}
                open={message.length > 0}/>
            <CardActions>
                <Button disabled={loading}
                        variant={'contained'}
                        color={'primary'}
                        size={"large"}
                        disableElevation={true}
                        className={classes.saleButton}
                        onClick={saveOrder}>
                    {translate('orderUpdate.save')}
                </Button>
            </CardActions>
            <Dialog open={success !== -1}>
                <DialogTitle>
                    {translate('orderUpdate.save')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate('orderUpdate.success')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={() => {
                        window.history.back()
                    }
                    }
                            color={'secondary'}>
                        {translate('orderUpdate.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

const OrderUpdateTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <OrderUpdate {...props} />
    </MuiThemeProvider>
);

export const OrderUpdateView = ({...props}) => {
    const id = props.location.pathname.replace("/orderUpdate/", "")
    return <OrderUpdateTheme id={id}/>
}

export const OrderUpdateTranslations = {
    en: {
        orderUpdate: {
            cancel: "Cancel",
            success: "Order updated successfully",
            save: "Save",
            deliveryFee: "Delivery Fee",
            disc: "Discount",
            discount: "Discount %",
            subtotal: "Subtotal",
            delete: "Delete",
            quantity: "Quantity",
            id: "ID",
            ok:"OK",
            barcode: "Barcode",
            product: "Product",
            stock: "Stock",
            itemPrice: "Item Price",
            totalPrice: "Total Price",
            action: "Actions",
            title: "Update Order",
            fail: "Failed to update.",
            messageAward: "Please enter award. You can enter 0",
            messageDiscount: "Please enter discount percentage. You can enter 0",
            messageDelivery: "Please enter delivery fee. You can enter 0",
            notFound: "Product not found.",
            total: "Total",
        }
    },
    tr: {
        updateOrder: {
            cancel: "İptal",
            ok:"OK",
            success: "Sipariş güncelledi.",
            save: "Kayıt",
            deliveryFee: "Delivery Fee",
            discount: "Discount %",
            subtotal: "Subtotal",
            delete: "Delete",
            quantity: "Quantity",
            id: "ID",
            disc: "Discount",
            barcode: "Barcode",
            product: "Product",
            stock: "Stock",
            itemPrice: "Item Price",
            totalPrice: "Total Price",
            action: "Actions",
            title: "Sipariş Güncellemesi",
            fail: "Güncelleme yapalmadı.",
            messageAward: "Please enter award. You can enter 0",
            messageDiscount: "Please enter discount percentage. You can enter 0",
            messageDelivery: "Please enter delivery fee. You can enter 0",
            notFound: "Product not found.",
            total: "Total",
        }
    },
}
import React, {Component, createRef} from 'react'
import {Map, Marker, TileLayer} from 'react-leaflet'
import SearchControl from "./SearchControl";

export default class MapView extends Component {
    state = {
        latLng: {
            lat: 41.015137,
            lng: 28.979530
        },
        zoom: 13
    }
    mapRef = createRef()

    componentDidMount() {
        let {record, source} = this.props;
        if (record !== undefined && record[source] !== undefined && record[source] !== null) {
            this.setState({latLng: record[source]})
        } else if (this.props.input !== undefined) {
            let {value} = this.props.input;
            if (value !== undefined && value.lat !== undefined && value.lng !== undefined) {
                this.setState({latLng: value})
            }
        }
    }

    handleClick = (e) => {
        if (this.props.editable) {
            this.props.input.onChange(e.latlng);
            this.setState({latLng: e.latlng})
        }
    }
    onSearchLocation = (latLng, info) => {
        if (this.props.editable) {
            this.props.input.onChange(latLng);
            this.setState({latLng: latLng})
        }
    }


    render() {
        const marker = this.state.latLng !== undefined && this.state.latLng !== null ?
            <Marker position={this.state.latLng}/> : null;
        return (
            <Map center={this.state.latLng} zoom={this.state.zoom}
                 ref={this.mapRef}
                 length={4}
                 onClick={this.handleClick}
                 style={{height: "400px"}}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {marker}
                {this.props.editable && <SearchControl showPopup={false}
                                                       onLocationSelect={this.onSearchLocation}
                                                       openSearchOnLoad={true}
                                                       position="topright"/>}
            </Map>
        )
    }
}

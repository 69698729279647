import React from "react";
import Icon from '@material-ui/icons/Whatshot';
import {
    AutocompleteArrayInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormTab,
    ImageField,
    List,
    maxLength,
    ReferenceArrayInput,
    required,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput
} from "react-admin";
import ImageUpload from "../common/ImageUpload";
import {Field} from 'react-final-form';
import {ListPagination} from "../common/Pagination";
import {SearchInput} from "../common/SearchInput";

export const CampaignIcon = Icon;

export const CampaignCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const CampaignEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CampaignFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={"English Name"}/>
        <SearchInput source={'name.turkish'} alwaysOn label={"Turkish Name"}/>
        <BooleanInput source={'slider'}/>
        <BooleanInput source={'home'}/>
    </Filter>
);
export const CampaignList = (props) => (
    <List {...props} bulkActionButtons={false}
          sort={{field: 'time', order: 'DESC'}}
          pagination={<ListPagination/>}
          perPage={50}
          filters={<CampaignFilters/>}>
        <CampaignGrid/>
    </List>
);

export const CampaignGrid = (props) => (
    <Datagrid {...props}>
        <TextField source={"name.turkish"}/>
        <BooleanField source={'home'}/>
        <BooleanField source={'slider'}/>
        <ShowButton/>
        <EditButton/>
        <DeleteWithConfirmButton/>
    </Datagrid>
);
export const CampaignShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source={"name.english"}/>
            <TextField source={"name.turkish"}/>
            <TextField source={"description.english"}/>
            <TextField source={"description.turkish"}/>
            <ImageField source={'image'}/>
        </SimpleShowLayout>
    </Show>
);

const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={"list"}>
        <FormTab label={"resources.campaigns.names"}>
            <TextInput source={"name.english"}
                       validate={[required(), maxLength(255)]}/>
            <TextInput source={"name.turkish"}
                       validate={[required(), maxLength(255)]}/>

            <TextInput source={"description.english"}
                       fullWidth
                       validate={[required(), maxLength(1000000)]}/>
            <TextInput source={"description.turkish"}
                       fullWidth
                       validate={[maxLength(1000000)]}/>
        </FormTab>
        <FormTab label={'resources.products.name'}>
            <ReferenceArrayInput source={'products'}
                                 filterToQuery={text => ({name: {turkish: text}})}
                                 reference={'products'}>
                <AutocompleteArrayInput source={'id'} optionText={'name.turkish'}/>
            </ReferenceArrayInput>
            <BooleanInput source={'home'}/>
            <BooleanInput source={'slider'}/>
            <BooleanInput source={'sendNotification'}/>
        </FormTab>
        <FormTab label={"resources.campaigns.fields.image"}>
            <Field name={"image"}
                   component={ImageUpload}
                   validate={required()}
                   source={"image"}/>
        </FormTab>
    </TabbedForm>
);
export const CampaignTranslations = {
    en: {
        campaigns: {
            name: "Campaigns",
            general: "General",
            names: "Name",
            fields: {
                name: {
                    english: "English Title",
                    turkish: "Turkish Title",
                },
                image: "Image",
                description: {
                    english: "Description",
                    turkish: "Turkish Description",
                },
                products: "Products",
                home: "Should Appear in Home?",
                slider: "Should appear in Slider?"
            },
        },
    },
    tr: {
        campaigns: {
            name: "Kampanyalar",
            general: "Genel",
            names: "İsim",
            fields: {
                name: {
                    english: "English başlık",
                    turkish: "Turkce başlık",
                },
                image: "resim",
                description: {
                    english: "Açıklama ingilizce",
                    turkish: "Açıklama türkçe",
                },
                products: "Ürünler",
                home: "Ana sayfada görünsün mü?",
                slider: "Slider da görünsün mü?"
            },
        },
    }
};
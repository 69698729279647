import * as React from "react";
import {Route} from 'react-router-dom';
import DepartmentReOrder from "./categories/DepartmentReorder";
import FaqReorder from "./faq/FaqReorder";
import ProductReorder from "./products/ProductReorder";
import CategoryReorder from "./categories/CategoryReorder";
import {OrderUpdateView} from "./products/UpdateOrder";

export default [
    <Route exact path="/departmentReorder" component={DepartmentReOrder}/>,
    <Route exact path="/faqReorder" component={FaqReorder}/>,
    <Route path={"/productReorder"} component={ProductReorder}/>,
    <Route path={"/categoryReorder"} component={CategoryReorder}/>,
    <Route path={"/orderUpdate"} component={OrderUpdateView}/>,

];
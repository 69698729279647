import React, {useState} from "react";
import {useMountEffect} from "../Util";
import {SERVER_IP} from "../index";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    MuiThemeProvider
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Title} from 'react-admin';
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import Button from "@material-ui/core/Button";
import {createMuiTheme} from '@material-ui/core/styles';
import {lightTheme} from "../layout/Layout";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 10,
        userSelect: 'none',
        borderRadius: 10,
    },
    titleDiv: {
        marginTop: 10,
        borderRadius: 10,
        padding: "2em",
        background: theme.palette.primary.main,
    },
    iconDiv: {
        height: 150,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 'medium',
        fontWeight: "bold",
        color: 'white',
        overflow: 'hidden',
        maxHeight: 50,
    },
    paperDiv: {
        textAlign: 'center',
        alignContent: 'center'
    },
    icon: {
        width: 100,
        height: 100,
        pointerEvents: 'none',
    },
}));

const gridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridGap: '16px',
};

const gridItemStyles = {
    height: '300px',
};

function FaqReOrder() {
    const classes = useStyles();
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState({text: "", state: false});
    useMountEffect(() => {
        setLoading(true);
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER_IP}/faq/getSeq/`, {
            headers: headers,
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoading(false);
                if (Array.isArray(data)) {
                    setDepartments(data);
                }
            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
                console.log(e);
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
        departments[oldIndex].sequence = newIndex;
        const deps = arrayMove(departments, oldIndex, newIndex);
        setDepartments(deps);
    };

    const onSave = () => {
        setSaving(true);
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        let body = departments.map((dep, index) => ({id: dep.id, seq: index}));
        fetch(`${SERVER_IP}/faq/updateSeq`,
            {method: "POST", headers: headers, body: JSON.stringify(body)})
            .then(response => {
                return response.json();
            })
            .then(() => {
                setMessage({text: "Sequence updated successfully.", state: true})
                setSaving(false);
            })
            .catch(e => {
                setMessage({text: `Failed to update sequence because ${e}`, state: true})
                setSaving(false);
                console.log(e);
            });
    }

    return <Card>
        <Title title="Arrange FAQ"/>
        <CardHeader title={<span>Drag to arrange FAQ</span>}/>
        <CardContent>
            {loading ? <CircularProgress size={24}/> :
                <div style={{height: 600, overflow: 'scroll'}}>
                    <SortableGrid faq={departments}
                                  onSortEnd={onSortEnd}
                                  axis={'xy'}
                                  distance={10}
                                  useWindowAsScrollContainer={true}
                                  helperClass='sortableHelper'
                                  classes={classes}/>
                </div>
            }
            <Button variant={'contained'}
                    disabled={saving}
                    size={'large'}
                    onClick={onSave}
                    color={'secondary'}>
                Save
                {saving && <CircularProgress size={20}/>}
            </Button>

        </CardContent>
        <Dialog open={message.state}>
            <DialogContent>
                <DialogContentText>
                    {message.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setMessage({state: false, text: message.text})}
                        color={'secondary'}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </Card>
}

const SortableGrid = SortableContainer(({faq, classes}) => (
    <div style={gridStyles}>
        {faq.map((tile, index) => (
            <DraggableTile tile={tile}
                           cols={1}
                           key={tile.id}
                           index={index}
                           classes={classes}/>
        ))}
    </div>
));

const DraggableTile = SortableElement(({tile, classes}) => (
    <div style={gridItemStyles}>
        <Paper className={classes.paper}>
            <div className={classes.paperDiv}>
                <div className={classes.titleDiv}>
                    <span className={classes.title}>{tile.question.english}</span>
                </div>
            </div>
        </Paper>
    </div>
));

const FaqReOrderTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <FaqReOrder {...props} />
    </MuiThemeProvider>
);

export default FaqReOrderTheme;
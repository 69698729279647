import React from "react";
import Icon from '@material-ui/icons/EmojiNature';
import {
    ArrayField,
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormDataConsumer,
    FormTab,
    FunctionField,
    ImageField,
    List,
    maxLength,
    minValue,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput
} from "react-admin";
import ImageUpload from "../common/ImageUpload";
import {Field} from 'react-final-form';
import {ProductsListBulkActions} from "./ProductsListBulkActions";
import {ListPagination} from "../common/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {TimeField} from "../common/TimeField";
import {DatePickerInput} from "../common/DatePickerInput";
import {StringArrayInput} from "../common/StringArrayInput";
import {Chip, InputAdornment} from "@material-ui/core";
import ProductPriceButton from "./ProductPriceButton";
import FilledTextField from '@material-ui/core/TextField';
import {ProductListActions} from "./ProductListActions";
import {SearchInput} from "../common/SearchInput";

export const ProductIcon = Icon;

export const styles = (theme) => ({
    price: {width: '15em'},
    category: {width: '20em'},
    date: {width: '20em', marginTop: '0.5em'},
    barcode: {width: '25em'},
    widthFormGroup: {display: 'inline-block', marginRight: 32},
    marginInput: {
        '&.MuiInputBase-root.Mui-disabled': {
            color: theme.palette.primary.main,
            fontWeight: 'bolder'
        }
    }
});
const unitTypes = [{id: "KG", name: "resources.products.unitTypes.kg"},
    {id: "BOX", name: "resources.products.unitTypes.box"},
    {id: "NUMBER", name: "resources.products.unitTypes.number"}]

const useStyles = makeStyles(styles);

export const ProductCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const ProductEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const ProductFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={"English Name"}/>
        <SearchInput source={'name.turkish'} alwaysOn label={"Turkish Input"}/>
        <SearchInput source={'barcode'} alwaysOn label={"Barcode"}/>
        <NumberInput source={'id'} alwaysOn/>
        <ReferenceInput source={'category.id'}
                        perPage={1000}
                        reference={'categories'} validate={required()}>
            <SelectInput optionText={'name.turkish'}/>
        </ReferenceInput>
        <NumberInput source={'stock'}/>
        <BooleanInput source={'enableStockManagement'}/>
        <BooleanInput source={'keepAlive'}/>
    </Filter>
);
export const ProductList = ({permissions, ...props}) => (
    <List {...props}
          pagination={<ListPagination/>}
          perPage={50}
          sort={{field: 'id', order: 'DESC'}}
          actions={permissions === "ROLE_ADMIN" && <ProductListActions/>}
          bulkActionButtons={permissions === "ROLE_ADMIN" && <ProductsListBulkActions/>}
          filters={<ProductFilters/>}>
        <ProductGrid permissions={permissions}/>
    </List>
);

function calculateMargin(record) {
    let difference = record.price - record.purchasePrice
    return ((difference / record.price) * 100).toFixed(0)
}

export const ProductGrid = ({permissions, ...props}) => (
    <Datagrid {...props}>
        <TextField source={'id'}/>
        <TextField source={"name.turkish"}/>
        <TextField source={'barcode'}/>
        <TextField source={'category.name.turkish'}/>
        <TextField source={'secondCategory.name.turkish'}/>
        {(permissions === "ROLE_ADMIN" || permissions === "ROLE_SALE_PERSON") && <TextField source={"purchasePrice"}/>}
        <TextField source={"price"}/>
        <TextField source={"newPrice"}/>
        {permissions === "ROLE_ADMIN" &&
            <FunctionField sortBy={'margin'}
                           label={"Margin"}
                           render={record => record !== undefined && `${Number(record.margin).toFixed(0)}%`}/>
        }
        <TextField source={"stock"}/>
        <TextField source={"award"}/>
        <FunctionField source={'tax'}
                       render={record => record === undefined || record.tax == null ? "0%" : `${record.tax}%`}/>
        <ProductPriceButton/>
        <ShowButton/>
        {permissions === "ROLE_ADMIN" && <EditButton/>}
        {permissions === "ROLE_ADMIN" && <DeleteWithConfirmButton/>}
    </Datagrid>
);
export const ProductShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source={'barcode'}/>
            <TextField source={"name.english"}/>
            <TextField source={"name.turkish"}/>

            <TextField source={"description.english"}/>
            <TextField source={"description.turkish"}/>

            <TextField source={'category.name.turkish'}/>
            <TextField source={'secondCategory.name.turkish'}/>
            <TextField source={'purchasePrice'}/>
            <TextField source={"price"}/>
            <TextField source={"newPrice"}/>
            <TextField source={'maximumOrderLimit'}/>
            <FunctionField source={'unitType'}
                           render={record => record === undefined || record.unitType == null ? "" : nameForType(record.unitType)}/>
            <TextField source={"stock"}/>
            <TextField source={'lowStockLimit'}/>
            <TextField source={"award"}/>
            <TimeField source={'expirationDate'}/>
            <ImageField source={"image"}/>
            <BooleanField source={'enableStockManagement'}/>
            <BooleanField source={'keepAlive'}/>
            <TextField source={'tax'}/>
            <FunctionField source={'tax'}
                           render={record => record === undefined || record.tax == null ? "0%" : `${record.tax}%`}/>
            <ArrayField source="extraBarcodes">
                <SingleFieldList>
                    <FunctionField render={record => <Chip label={record}/>}/>
                </SingleFieldList>
            </ArrayField>
            <TextField source={'boxUnitNumber'}/>
        </SimpleShowLayout>
    </Show>
);

const nameForType = unitType => {
    if (unitType === "KG") {
        return "KG";
    } else if (unitType === "NUMBER") {
        return "Number";
    }
    return "Box";
}

const CreateOrEdit = (props) => {
    const classes = useStyles();
    return (
        <TabbedForm {...props} redirect={"list"}>
            <FormTab label={"resources.products.names"}>
                <TextInput source={"barcode"}
                           className={classes.barcode}
                           formClassName={classes.widthFormGroup}
                           validate={[required(), maxLength(255)]}/>
                <Field name={'extraBarcodes'}
                       label={"Extra Barcodes"}
                       component={StringArrayInput}
                       source={"extraBarcodes"}/>
                <span/>

                <TextInput source={"name.english"}
                           className={classes.barcode}
                           formClassName={classes.widthFormGroup}
                           validate={[required(), maxLength(255)]}/>

                <TextInput source={"name.turkish"}
                           className={classes.barcode}
                           formClassName={classes.widthFormGroup}
                           validate={[required(), maxLength(255)]}/>

                <span/>

                <TextInput source={"description.english"}
                           fullWidth
                           validate={[required(), maxLength(1000000)]}/>
                <TextInput source={"description.turkish"}
                           fullWidth
                           validate={[required(), maxLength(1000000)]}/>

                <BooleanInput source={'keepAlive'}/>
            </FormTab>
            <FormTab label={'resources.products.general'}>
                <NumberInput source={'purchasePrice'}
                             className={classes.price}
                             formClassName={classes.widthFormGroup}
                             validate={[required(), minValue(1)]}/>
                <NumberInput source={'tax'}
                             className={classes.price}
                             InputProps={{
                                 endAdornment: <InputAdornment position="start">%</InputAdornment>,
                             }}
                             formClassName={classes.widthFormGroup}
                             validate={[required(), minValue(0)]}/>
                <NumberInput source={'price'} validate={[required(), minValue(1)]}
                             className={classes.price}
                             formClassName={classes.widthFormGroup}
                />
                <FormDataConsumer className={classes.price}
                                  formClassName={classes.widthFormGroup}>
                    {({formData, ...rest}) => (
                        formData.price && formData.purchasePrice &&
                        <FilledTextField
                            variant={'filled'}
                            label={'Margin'}
                            margin={'dense'}
                            InputProps={{className: classes.marginInput}}
                            value={calculateMargin({
                                price: formData.price,
                                purchasePrice: formData.purchasePrice
                            }) + "%"}
                            disabled/>

                    )}
                </FormDataConsumer>
                <span/>
                <NumberInput source={"newPrice"} validate={[minValue(1)]}
                             className={classes.price}
                             formClassName={classes.widthFormGroup}/>
                <FormDataConsumer className={classes.price}
                                  formClassName={classes.widthFormGroup}>
                    {({formData, ...rest}) => (
                        formData.newPrice && formData.purchasePrice &&
                        <FilledTextField
                            variant={'filled'}
                            label={'Discounted Margin'}
                            margin={'dense'}
                            InputProps={{className: classes.marginInput}}
                            value={calculateMargin({
                                price: formData.newPrice,
                                purchasePrice: formData.purchasePrice
                            }) + "%"}
                            disabled/>
                    )}
                </FormDataConsumer>
                <span/>
                <SelectInput source={'unitType'} choices={unitTypes} validate={required()}
                             className={classes.category}
                             formClassName={classes.widthFormGroup}/>
                <FormDataConsumer>
                    {({formData, ...rest}) => (
                        formData.unitType === "BOX" && <NumberInput
                            source="boxUnitNumber"
                            className={classes.category}
                            formClassName={classes.widthFormGroup}
                            {...rest}
                        />
                    )}
                </FormDataConsumer>
                <span/>

                <NumberInput source={'maximumOrderLimit'} validate={[required(), minValue(1)]}
                             className={classes.category}
                             formClassName={classes.widthFormGroup}/>
                <NumberInput source={'minimumOrderLimit'} validate={[minValue(1)]}
                             className={classes.category}
                             formClassName={classes.widthFormGroup}/>
                <NumberInput source={'stock'}
                             className={classes.price}
                             formClassName={classes.widthFormGroup}
                             validate={[required(), minValue(0)]}/>
                <NumberInput source={'lowStockLimit'}
                             className={classes.price}
                             formClassName={classes.widthFormGroup}/>
                <span/>

                <NumberInput source={'award'}
                             className={classes.price}
                             formClassName={classes.widthFormGroup}
                             helperText={"On buy award user with bonus points"}/>
                <Field name={'expirationDate'}
                       className={classes.date}
                       formClassName={classes.widthFormGroup}
                       component={DatePickerInput}
                       label={'resources.products.fields.expirationDate'}
                       source={'expirationDate'}/>
                <span/>
                <ReferenceInput source={'category.id'} reference={'categories'}
                                perPage={1000}
                                filterToQuery={text => ({"name.turkish": text})}
                                className={classes.category}
                                formClassName={classes.widthFormGroup}
                                validate={required()}>
                    <AutocompleteInput optionText={'name.turkish'}/>
                </ReferenceInput>
                <ReferenceInput source={'secondCategory.id'}
                                className={classes.category}
                                formClassName={classes.widthFormGroup}
                                reference={'categories'}
                                filterToQuery={text => ({"name.turkish": text})}
                                perPage={1000}>
                    <AutocompleteInput optionText={'name.turkish'}/>
                </ReferenceInput>
                <BooleanInput source={'enableStockManagement'}/>

            </FormTab>
            <FormTab label={"resources.products.fields.image"}>
                <Field name={"image"}
                       component={ImageUpload}
                       validate={required()}
                       source={"image"}/>
            </FormTab>
        </TabbedForm>);
};
export const ProductTranslations = {
    en: {
        products: {
            name: "Products",
            general: "General",
            names: "Name",
            unitTypes: {
                kg: "KG",
                box: "Box",
                number: "Number"
            },
            fields: {
                name: {
                    english: "English Title",
                    turkish: "Turkish Title",
                },
                expirationDate: "Expiration Date",
                lowStockLimit: "Low Stock Limit",
                image: "Image",
                description: {
                    english: "Description",
                    turkish: "Turkish Description",
                },
                category: {
                    id: "Category",
                    name: {
                        turkish: "Category"
                    },
                },
                secondCategory: {
                    id: "Second Category",
                    name: {
                        turkish: "Second Category"
                    },
                },
                price: "Sale Price",
                stock: "Stock",
                award: "Award",
                newPrice: "Discount Price",
                enableStockManagement: "Stock Management Enabled?",
                purchasePrice: "Purchase Price"
            },
        },
    },
    tr: {
        products: {
            name: "Ürünler",
            general: "Genel",
            names: "Adı",
            unitTypes: {
                kg: "KG",
                box: "Kutu",
                number: "Numara"
            },
            fields: {
                name: {
                    english: "İngilizce Adı",
                    turkish: "Türkçe Adı",
                },
                expirationDate: "Son kullanma tarihi",
                lowStockLimit: "Düşük Stok Limiti",
                image: "Resim",
                description: {
                    english: "İngilizce Açıklama",
                    turkish: "Türkçe Açıklama",
                },
                category: {
                    id: "Kategori",
                    name: {
                        turkish: "Kategori"
                    },
                },
                secondCategory: {
                    id: "İkinci Kategori",
                    name: {
                        turkish: "İkinci Kategori",
                    },
                },
                price: "Satiş fiyat",
                stock: "Stok",
                award: "Kredit",
                newPrice: "İndirimli fiyat",
                enableStockManagement: "Stok Yönetimi Aktif?",
                purchasePrice: "Alış fiyatı"
            },
        },
    }
};
import React from "react";
import {
    BooleanField,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    List,
    maxLength,
    minLength,
    PasswordInput,
    ReferenceManyField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import DriverActions from "./UserActions";
import {OrderGrid} from "../products/Order";


export const DriverCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={'list'}>
            <TextInput source={"name"}
                       validate={[required(), maxLength(100)]}/>
            <TextInput source={'plateNo'} validate={[required(), maxLength(12)]}/>
            <BooleanInput source={"enabled"}/>
            <TextInput source={"username"} validate={[required(), minLength(4), maxLength(30)]}/>
            <PasswordInput source={"password"} validate={[required(), minLength(6), maxLength(30)]}/>

        </SimpleForm>
    </Create>
);

export const DriverEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source={"name"} validate={required()}/>
            <TextInput source={'plateNo'} validate={[required(), maxLength(12)]}/>
            <BooleanInput source={"enabled"}/>
            <TextInput source={"username"}
                       disabled
                       validate={[required(), minLength(4)]}/>
            <PasswordInput source={"password"}
                           disabled
                           validate={[required(), minLength(6)]}/>
        </SimpleForm>
    </Edit>
);


export const DriverList = ({permissions, ...props}) => (
    <List {...props}
          sort={{field: 'id', order: 'DESC'}}
          bulkActionButtons={false}>
        <Datagrid>
            <TextField source={"name"}/>
            <TextField source={"username"}/>
            <TextField source={'plateNo'}/>
            <BooleanField source={"enabled"}/>
            <ShowButton/>
            {permissions === "ROLE_ADMIN" && <EditButton/>}
            {permissions === "ROLE_ADMIN" && <DeleteWithConfirmButton/>}
        </Datagrid>
    </List>
);

export const DriverShow = (props) => (
    <Show {...props} actions={<DriverActions/>}>
        <TabbedShowLayout>
            <Tab label={'resources.drivers.general'}>
                <ChipField source={"name"}/>
                <ChipField source={"username"}/>
                <TextField source={'plateNo'}/>
                <BooleanField source={"enabled"}/>
            </Tab>
            <Tab label={'resources.drivers.orders'}>
                <ReferenceManyField reference={'orders'} target={'driver.id'} addLabel={false}>
                    <OrderGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
export const DriverTranslations = {
    en: {
        drivers: {
            name: "Drivers",
            general: "General",
            orders: "Assigned Orders",
            fields: {
                enabled: "Enabled",
                name: "Name",
                username: "username",
                password: "Password",
                plateNo: "Car Plate",
            }
        }
    },
    tr: {
        drivers: {
            name: "Sürücüler",
            general: "Genel",
            orders: "Atanan siparişler",
            fields: {
                enabled: "Aktif",
                name: "İsim",
                username: "Kullancı Adı",
                password: "Şifre",
                plateNo: "Araç Plaka",
            }
        }
    }
};
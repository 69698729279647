import React from "react";
import {Filter, List} from "react-admin";
import {Field} from "react-final-form";
import {DatePickerView} from "./OrderReport";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {TableCell} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

export const Filters = (props) => {
    let start = props.filterValues.start;
    let end = props.filterValues.end;
    let startDate = new Date();
    let endDate = new Date();
    if (start !== undefined) {
        startDate.setTime(start);
    }
    if (end !== undefined) {
        endDate.setTime(end)
    }
    return <Filter {...props}>
        <Field component={DatePickerView}
               alwaysOn
               start
               source={"startTime"}
               name={'startTime'}
               label={"resources.orderReports.fields.startDate"}
               max={new Date()}/>
        <Field component={DatePickerView}
               {...props}
               alwaysOn
               source={"endTime"}
               name={'endTime'}
               max={new Date()}
               label={"resources.orderReports.fields.endDate"}/>

    </Filter>
};

export const ListSaleReport = props => (
    <List {...props} filters={<Filters/>}
          pagination={false}
          basePath={"salesReports/shop"}
          bulkActionButtons={false} exporter={false}>
        <ReportTable/>
    </List>
)

function ccyFormat(num) {
    return num === 0 ? "0" : `${num.toFixed(2)}`;
}

function ReportTable({ids, data}) {
    let sales = 0;
    let refunds = 0;
    let saleMoney = 0;
    let awards = 0;
    let delivery = 0;
    let discountPercentage = 0;
    let refundsMoney = 0;
    let profit = 0;
    let discountPrice = 0
    return <Table>
        <TableHead>
            <TableRow>
                <TableCell><b>#</b></TableCell>
                <TableCell><b>Time</b></TableCell>
                <TableCell><b>Type</b></TableCell>
                <TableCell><b>ID</b></TableCell>
                <TableCell><b>Unit Sold</b></TableCell>
                <TableCell><b>Gross Price</b></TableCell>
                <TableCell><b>Discount(%)</b></TableCell>
                <TableCell><b>Discount</b></TableCell>
                <TableCell><b>Award</b></TableCell>
                <TableCell><b>Delivery</b></TableCell>
                <TableCell><b>Products Price</b></TableCell>
                <TableCell><b>Final Price</b></TableCell>
                <TableCell><b>Profit</b></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {ids.map((id, index) => {

                let record = data[id];
                if (record.type === "SALE") {
                    sales += 1;
                    saleMoney += record.finalPrice
                    awards += record.award;
                    delivery += record.delivery;
                    discountPercentage += record.discount;
                    discountPrice += record.discountPrice;
                    profit += record.profit;
                } else {
                    refunds += 1;
                    refundsMoney += record.finalPrice
                    awards -= record.award;
                    delivery -= record.delivery;
                    discountPercentage -= record.discount;
                    discountPrice -= record.discountPrice;
                    profit -= record.profit;
                }
                return <TableRow key={id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{formatDate(record.time)}</TableCell>
                    <TableCell>{record.type}</TableCell>
                    <TableCell>{record.id}</TableCell>
                    <TableCell>{record.unitSold}</TableCell>
                    <TableCell>{ccyFormat(record.grossPrice)}</TableCell>
                    <TableCell>{ccyFormat(record.discount)}</TableCell>
                    <TableCell>{ccyFormat(record.discountPrice)}</TableCell>
                    <TableCell>{ccyFormat(record.award)}</TableCell>
                    <TableCell>{ccyFormat(record.delivery)}</TableCell>
                    <TableCell>{ccyFormat(record.productsPrice)}</TableCell>
                    <TableCell>{ccyFormat(record.finalPrice)}</TableCell>
                    <TableCell>{ccyFormat(record.profit)}</TableCell>
                </TableRow>
            })}

            <TableRow>
                <TableCell rowSpan={9} colSpan={10}/>
                <TableCell><b>#</b></TableCell>
                <TableCell><b>Quantity</b></TableCell>
                <TableCell><b>Total Price</b></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><b>Sales Total</b></TableCell>
                <TableCell>{sales}</TableCell>
                <TableCell>{ccyFormat(saleMoney)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell><b>Refunds Total</b></TableCell>
                <TableCell>{refunds}</TableCell>
                <TableCell>{ccyFormat(refundsMoney)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2} align={'center'}><b>G. Total</b></TableCell>
                <TableCell><b>{ccyFormat(saleMoney - refundsMoney)}</b></TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2}><b>Total Discount(%)</b></TableCell>
                <TableCell>{ccyFormat(discountPercentage)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2}><b>Total Discount</b></TableCell>
                <TableCell>{ccyFormat(discountPrice)}</TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={2}><b>Total Awards</b></TableCell>
                <TableCell>{ccyFormat(awards)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2}><b>Total Profit</b></TableCell>
                <TableCell>{ccyFormat(profit)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2}><b>Total Delivery</b></TableCell>
                <TableCell>{ccyFormat(delivery)}</TableCell>
            </TableRow>


        </TableBody>
    </Table>
}

function formatDate(epoch) {
    return new Date(epoch).toLocaleString()
}


export const SaleReportTranslations = {
    en: {
        "saleReports/shop": {
            name: "Shop Report",
            fields: {
                time: "Date",
                product: {
                    name: {
                        turkish: "Name",
                    }
                },
                unitSold: "Unit Sold",
                price: "Price",
                profit: "Profit"
            }
        },
        "saleReports/depo": {
            name: "Depo Report",
            fields: {
                time: "Date",
                product: {
                    name: {
                        turkish: "Name",
                    }
                },
                unitSold: "Unit Sold",
                price: "Price",
                profit: "Profit"
            }
        }
    },
    tr: {
        "saleReports/shop": {
            name: "Shop Report",
            fields: {
                time: "Date",
                product: {
                    name: {
                        turkish: "Name",
                    }
                },
                unitSold: "Unit Sold",
                price: "Price",
                profit: "Profit"
            }
        },
        "saleReports/depo": {
            name: "Depo Report",
            fields: {
                time: "Date",
                product: {
                    name: {
                        turkish: "Name",
                    }
                },
                unitSold: "Unit Sold",
                price: "Price",
                profit: "Profit"
            }
        }
    }
}
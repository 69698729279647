import React, {Fragment, useState} from "react";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import {Dialog, DialogActions, MuiThemeProvider, TextField} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import {lightTheme} from "../layout/Layout";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SERVER_IP} from "../index";
import download from 'downloadjs';

function ProductPriceButton({record}) {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [manual, setManual] = useState("")

    function handleClose() {
        setOpen(false)
    }

    function handlePrint() {
        if (record == null) {
            return
        }
        setLoading(true)
        let manualPrice = Number(manual)
        let url;
        if (Number.isNaN(manualPrice) || manualPrice < 0 || manualPrice === 0) {
            url = encodeURI(`${SERVER_IP}/products/generatePriceTag/${record.id}`);
        } else {
            url = encodeURI(`${SERVER_IP}/products/generatePriceTag/${record.id}?price=${manualPrice}`);
        }
        let x = new XMLHttpRequest();
        x.open("GET", url, true);
        x.setRequestHeader("Authorization", `Bearer ${localStorage.getItem('token')}`)
        x.setRequestHeader("Access-Control-Allow-Origin", "*");
        x.responseType = "blob";
        x.onload = function (e) {
            let filename = "";
            let disposition = e.currentTarget.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('inline') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            download(e.target.response, filename, "application/pdf");
        };
        x.send();
        setOpen(false)
        setLoading(false)
    }

    return (
        <Fragment>
            <Button color={'secondary'} onClick={() => setOpen(true)}>
                <PrintIcon/> Print Price
            </Button>
            <Dialog open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">Print Price for "{record != null ? record.name.turkish : ""}"</DialogTitle>
                <DialogContent>
                    <TextField color={'secondary'}
                               label={"Manual Price"}
                               value={manual}
                               id={"manual-price"}
                               onChange={event => setManual(event.target.value)}
                               helperText={"If you want to print manual price, you enter it here. Leave it empty for printing default product price"}
                               variant={'filled'}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading}
                            variant={'text'} color={'primary'}
                            onClick={() => {
                                setOpen(false)
                            }}>
                        Cancel
                    </Button>
                    <Button disabled={loading} variant={'text'}
                            color={'primary'}
                            onClick={handlePrint}>
                        {loading && (
                            <CircularProgress size={25} thickness={2}/>
                        )}
                        Print
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

const Themed = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <ProductPriceButton {...props}/>
    </MuiThemeProvider>
)
export default Themed;
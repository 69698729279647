import React, {cloneElement} from "react";
import Icon from '@material-ui/icons/Extension';
import {Field} from 'react-final-form';
import {Link as RouterLink} from 'react-router-dom';
import OrderIcon from '@material-ui/icons/ViewQuilt';
import {
    BooleanField,
    BooleanInput,
    Button,
    Create,
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    Filter,
    FormTab,
    ImageField,
    List,
    maxLength,
    ReferenceManyField,
    required,
    sanitizeListRestProps,
    Show,
    ShowButton,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
} from "react-admin";
import ImageUpload from "../common/ImageUpload";
import {CategoryGrid} from "./Category";
import {ListPagination} from "../common/Pagination";
import {SearchInput} from "../common/SearchInput";
import {CategoryReOrderButton} from "./CategoryReorder";

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button color="primary"
                    label={"Rearrange Order"}
                    component={RouterLink} to="/departmentReorder">
                <OrderIcon/>
            </Button>
            {hasCreate && <CreateButton basePath={basePath}/>}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />

        </TopToolbar>
    );
};

export const DepartmentIcon = Icon;
export const DepartmentCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const DepartmentEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const DepartmentFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={"English Name"}/>
        <SearchInput source={'name.turkish'} alwaysOn label={"Turkish Name"}/>
        <BooleanInput source={'onlyPanel'}/>

    </Filter>
);
export const DepartmentList = (props) => (
    <List {...props} bulkActionButtons={false}
          actions={<ListActions/>}
          pagination={<ListPagination/>}
          perPage={50}
          sort={{field: 'time', order: 'DESC'}}
          filters={<DepartmentFilters/>}>
        <Datagrid>
            <TextField source={"name.turkish"}/>
            <TextField source={"description.english"}/>
            <CategoryReOrderButton/>
            <ShowButton/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);
export const DepartmentShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={'resources.departments.general'}>
                <TextField source={"name.turkish"}/>
                <TextField source={"name.english"}/>
                <TextField source={"description.english"}/>
                <TextField source={"description.turkish"}/>
                <BooleanField source={'onlyPanel'}/>
                <ImageField source={"image"}/>
            </Tab>
            <Tab label={'resources.categories.name'}>
                <ReferenceManyField reference={'categories'} target={'department.id'} addLabel={false}>
                    <CategoryGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={"list"}>
        <FormTab label={"resources.departments.general"}>

            <TextInput source={"name.english"}
                       validate={[required(), maxLength(255)]}/>
            <TextInput source={"name.turkish"}
                       validate={[required(), maxLength(255)]}/>

            <TextInput source={"description.english"}
                       fullWidth
                       multiline
                       validate={[required(), maxLength(1000000)]}/>
            <TextInput source={"description.turkish"}
                       fullWidth
                       multiline
                       validate={[required(), maxLength(1000000)]}/>

            <BooleanInput source={'onlyPanel'}/>
        </FormTab>
        <FormTab label={"resources.departments.fields.image"}>
            <Field name={"image"}
                   validate={required()}
                   component={ImageUpload}
                   source={"image"}/>
        </FormTab>
    </TabbedForm>
);

export const DepartmentTranslations = {
    en: {
        departments: {
            name: "Departments",
            general: "General",
            fields: {
                name: {
                    english: "English Title",
                    turkish: "Turkish Title",
                },
                onlyPanel: "Only Panel",
                image: "Image",
                description: {
                    english: "Description",
                    turkish: "Turkish Description",
                },
            },
        },
    },
    tr: {
        departments: {
            name: "Departmanlar",
            general: "Genel",
            fields: {
                name: {
                    english: "İngilizce Adı",
                    turkish: "Türkçe Adı",
                },
                image: "Resim",
                onlyPanel: "Sadece panel için",
                description: {
                    english: "İngilizce Açıklama",
                    turkish: "Türkçe Açıklama",
                },
            },
        },
    }
};
import React from "react";
import Icon from "@material-ui/icons/Category";
import {Field} from 'react-final-form';
import {
    BooleanInput,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormTab,
    ImageField,
    List,
    maxLength, maxValue, minValue, NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
} from "react-admin";
import ImageUpload from "../common/ImageUpload";
import {ListPagination} from "../common/Pagination";
import {SearchInput} from "../common/SearchInput";
import {ProductReOrderButton} from "../products/ProductReorder";

export const CategoryIcon = Icon;
export const CategoryCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const CategoryEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CategoryFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={"English Name"}/>
        <SearchInput source={'name.turkish'} alwaysOn label={"Turkish Name"}/>
        <BooleanInput source={'onlyPanel'}/>

        <ReferenceInput source={'department.id'}
                        perPage={1000}
                        reference={'departments'}>
            <SelectInput optionText={'name.turkish'}/>
        </ReferenceInput>
    </Filter>
);
export const CategoryList = (props) => (
    <List {...props} bulkActionButtons={false}
          sort={{field: 'id', order: 'DESC'}}
          pagination={<ListPagination/>}
          perPage={50}
          filters={<CategoryFilters/>}>
        <CategoryGrid/>
    </List>
);

export const CategoryGrid = (props) => (
    <Datagrid {...props}>
        <TextField source={"name.turkish"}/>
        <ReferenceField source={'department.id'} reference={'departments'}>
            <TextField source={'name.turkish'}/>
        </ReferenceField>
        <TextField source={"description.turkish"}/>
        <ShowButton/>
        <ProductReOrderButton/>
        <EditButton/>
        <DeleteWithConfirmButton/>
    </Datagrid>
);
export const CategoryShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source={"name.english"}/>
            <TextField source={"name.turkish"}/>
            <TextField source={"description.english"}/>
            <TextField source={"description.turkish"}/>
            <ReferenceField source={'department.id'} reference={'departments'}>
                <TextField source={'name.turkish'}/>
            </ReferenceField>
            <ImageField source={"image"}/>
        </SimpleShowLayout>
    </Show>
);
const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={"list"}>
        <FormTab label={"resources.categories.general"}>

            <TextInput source={"name.english"}
                       validate={[required(), maxLength(255)]}/>
            <TextInput source={"name.turkish"}
                       validate={[required(), maxLength(255)]}/>


            <ReferenceInput source={'department.id'}
                            perPage={1000}
                            reference={'departments'} validate={required()}>
                <SelectInput optionText={'name.turkish'}/>
            </ReferenceInput>
            <TextInput source={"description.english"}
                       fullWidth
                       validate={[required(), maxLength(1000000)]}/>
            <TextInput source={"description.turkish"}
                       fullWidth
                       validate={[required(), maxLength(1000000)]}/>

            <BooleanInput source={'onlyPanel'}/>

        </FormTab>
        <FormTab label={"resources.categories.fields.image"}>
            <Field name={"image"}
                   component={ImageUpload}
                   source={"image"}/>
        </FormTab>
        <FormTab label={"Saatlar"}>
            <NumberInput source={'shift.open.hour'}
                         label={"Açık Saat"}
                         options={{helperText: "0 demek 12 gece."}}
                         validate={[minValue(0), maxValue(23)]}/>
            <NumberInput source={'shift.open.minute'}
                         label={"Açık Dakika"}
                         validate={[minValue(0), maxValue(59)]}/>
            <NumberInput label={"Kapalı Saat"}
                         source={'shift.close.hour'}
                         validate={[minValue(0), maxValue(23)]}/>
            <NumberInput label={"Kapalı Dakika"}
                         source={'shift.close.minute'}
                         validate={[minValue(0), maxValue(59)]}/>
        </FormTab>
    </TabbedForm>
);

export const CategoryTranslations = {
    en: {
        categories: {
            name: "Categories",
            general: "General",
            fields: {
                name: {
                    english: "English Title",
                    turkish: "Turkish Title",
                },
                image: "Image",
                description: {
                    english: "Description",
                    turkish: "Turkish Description",
                },
                department: {
                    id: "Department"
                }
            },
        },
    },
    tr: {
        categories: {
            name: "Kategoriler",
            general: "Genel",
            fields: {
                name: {
                    english: "İngilizce Adı",
                    turkish: "Türkçe Adı",
                },
                image: "Resim",
                description: {
                    english: "İngilizce Açıklama",
                    turkish: "Türkçe Açıklama",
                },
                department: {
                    id: "Departman"
                }
            },
        },
    }
};
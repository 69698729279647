import React from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    email,
    List,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";

export const NotificationEmailList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField source={'id'}/>
            <TextField source={'email'}/>
            <BooleanField source={'enabled'}/>
            <EditButton/>
        </Datagrid>
    </List>
);

const CreateOrEdit = props => (
    <SimpleForm {...props} redirect={'list'}>
        <TextInput source={'email'} validate={email()}/>
        <BooleanInput source={'enabled'}/>
    </SimpleForm>
);
export const NotificationEmailCreate = props => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
)

export const NotificationEmailEdit = props => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

export const NotificationEmailTranslations = {
    en: {
        notificationEmails: {
            name: "Emails",
            fields: {
                id: "ID",
                email: "Email",
                enabled: "Enabled"
            }
        }
    },
    tr: {
        notificationEmails: {
            name: "Emails",
            fields: {
                id: "ID",
                email: "Email",
                enabled: "Aktif?"
            }
        }
    }
}
import * as React from "react";
import {useState} from "react";
import {serverExport} from "../Util";
import {Button} from "react-admin";
import {CircularProgress} from "@material-ui/core";
import {SERVER_IP} from "../index";
import ReceiptIcon from "@material-ui/icons/Receipt";

export function EFaturaButton({record}) {
    const [loading, setLoading] = useState(false)

    function doExport() {
        setLoading(true)
        serverExport("", false, () => {
            setLoading(false)
        }, `${SERVER_IP}/orders/efatura/${record.id}`)
    }

    return <Button startIcon={<ReceiptIcon/>}
                   label={"E-Fatura"}
                   disabled={record === undefined || loading}
                   onClick={() => doExport()}
                   color={'primary'}>
        {loading ? <CircularProgress size={16} color={'secondary'}/> : undefined}
    </Button>
}

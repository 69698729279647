import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Dialog, DialogContent,} from "@material-ui/core";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import makeStyles from "@material-ui/core/styles/makeStyles";
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function ShowReceiptView({orderId, open, onClose}) {
    const classes = useStyles();
    const [html, setHtml] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        onClose();
    };
    const printReceipt = () => {
        let token = localStorage.getItem("token");
        window.open(`${SERVER_IP}/orders/receipt/pdf/${orderId}?token=${token}`,
            "_blank")
    }

    useEffect(() => {
        if (orderId === undefined) {
            return;
        }
        fetchJson(`${SERVER_IP}/orders/receipt/${orderId}`)
            .then(text => {
                console.log(text);
                setLoading(false);
                setHtml(text.body)
            })
            .catch(e => {
                setLoading(false);
                console.log(e);
            })
    }, [orderId])
    return <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        fullScreen
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon/>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {`Receipt #${orderId}`}
                </Typography>
                <Button color="inherit"
                        startIcon={<PrintIcon/>}
                        variant={'text'} onClick={printReceipt}>Print</Button>
            </Toolbar>
        </AppBar>
        <DialogContent dividers>
            {loading ? <CircularProgress/> : <div dangerouslySetInnerHTML={{__html: html}}/>}
        </DialogContent>

    </Dialog>
}
import React from "react";
import Button from "@material-ui/core/Button";
import ConfirmIcon from "@material-ui/icons/ThumbUp";


export function OrderConfirmButton({order}) {
    return <Button variant={'text'}
                   disabled={order.status === "CONFIRMED"}
                   color={'secondary'}
                   startIcon={<ConfirmIcon/>}>
        Confirm
    </Button>
}
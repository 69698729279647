import React, {cloneElement} from "react";
import Icon from '@material-ui/icons/ContactSupport';
import {
    Button,
    Create,
    CreateButton,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    FormTab,
    List,
    required,
    sanitizeListRestProps,
    TabbedForm,
    TextField,
    TextInput,
    TopToolbar,
    useListContext
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import OrderIcon from "@material-ui/icons/ViewQuilt";
import {Link as RouterLink} from 'react-router-dom';

export const FaqIcon = Icon;
const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button color="primary"
                    label={"Rearrange Order"}
                    component={RouterLink} to="/faqReorder">
                <OrderIcon/>
            </Button>
            {hasCreate && <CreateButton basePath={basePath}/>}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />

        </TopToolbar>
    );
};

const CreateOrEdit = (props) => (<TabbedForm {...props} redirect={'list'}>

    <FormTab label={"English"}>
        <TextInput source={'question.english'} fullWidth validate={required()}/>
        <RichTextInput source={'answer.english'} fullWidth validate={required()}
                       toolbar={[['bold', 'italic', 'underline']]}
                       multiline/>
    </FormTab>
    <FormTab label={"Turkish"}>
        <TextInput source={'question.turkish'} fullWidth validate={required()}/>
        <RichTextInput source={'answer.turkish'} fullWidth validate={required()}
                       toolbar={[['bold', 'italic', 'underline']]}
                       multiline/>
    </FormTab>
</TabbedForm>);

export const FaqCreate = (props) => (<Create {...props}>
    <CreateOrEdit/>
</Create>);

export const FaqEdit = (props) => (<Edit {...props}>
    <CreateOrEdit/>
</Edit>);

export const FaqList = props => (
    <List {...props} bulkActionButtons={false} actions={<ListActions/>}>
        <Datagrid>
            <TextField source={'question.turkish'}/>
            <DateField source={'time'} showTime/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>);

export const FaqTranslations = {
    en: {
        faq: {
            name: "FAQ",
            fields: {
                question: {
                    english: "English Question", turkish: "Turkish Question",
                }, answer: {
                    english: "English Answer", turkish: "Turkish Answer",
                }
            }
        },
    },
    tr: {
        faq: {
            name: "SSS",
            fields: {
                question: {
                    english: "Ingilizce Soru", turkish: "Turkce Sorular",
                }, answer: {
                    english: "Ingilizce Cevap", turkish: "Turkce Cevap",
                }
            }
        },
    }
};
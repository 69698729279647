import * as React from 'react';
import {cloneElement} from 'react';
import {CreateButton, sanitizeListRestProps, TopToolbar, translate,} from 'react-admin';
import ResetStockButton from "./ResetStockButton";
import ResetAliveButton from "./ResetAliveButton";
import {ExportButton} from "../common/ExportButton";

export const ProductListActions = translate(({
                                                 currentSort,
                                                 className,
                                                 resource,
                                                 filters,
                                                 displayedFilters,
                                                 exporter,
                                                 filterValues,
                                                 permanentFilter,
                                                 hasCreate,
                                                 basePath,
                                                 selectedIds,
                                                 onUnselectItems,
                                                 showFilter,
                                                 maxResults,
                                                 total,
                                                 translate,
                                                 excelExporter,
                                                 ...rest
                                             }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <ResetStockButton/>
        <ResetAliveButton/>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <CreateButton basePath={basePath}/>}
        <ExportButton path={"products"} pdf={true} total={total}/>
        <ExportButton path={"products"} pdf={false} total={total}/>
    </TopToolbar>
));


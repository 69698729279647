import raTr from "./raTr";
import {AddressTranslations, UserTranslations} from "../user/User";
import {CategoryTranslations} from "../categories/Category";
import {DepartmentTranslations} from "../categories/Department";
import {CityTranslations} from "../address/City";
import {DistrictTranslations} from "../address/District";
import {TownTranslations} from "../address/Town";
import {DriverTranslations} from "../user/Driver";
import {ProductTranslations} from "../products/Product";
import {CampaignTranslations} from "../products/Campaign";
import {OrderTranslations} from "../products/Order";
import {OrderReportTranslations} from "../report/OrderReport";
import {NotificationEmailTranslations} from "../settings/NotificationEmail";
import {NotificationTranslations} from "../notification/Notification";
import {ConfigurationStrings} from "../settings/Configuration";
import {SaleTranslations} from "../sale/SaleList";
import {RefundTranslations} from "../sale/RefundList";
import {SaleReportTranslations} from "../report/ProductSaleReport";
import {DepoSaleUserTranslations, SaleUserTranslations, ShopSaleUserTranslations} from "../user/SaleUser";
import {FaqTranslations} from "../Faq";
import {UserResetTranslations} from "../user/ResetDeviceView";
import {MenuTranslations} from "../layout/Menu";
import {PendingOrderTranslations} from "../dashboard/PendingOrder";
import {DeliverOrderTranslations} from "../products/DeliverOrderView";
import {MarkOutOfStockTranslations} from "../products/MarkOutOfStockView";
import {ProductsListActionTranslations} from "../products/ProductsListBulkActions";
import {ImageUploadTranslation} from "../common/ImageUpload";
import {DepartmentOrderTranslations} from "../categories/DepartmentReorder";
import {SaleCreateTranslations} from "../sale/SaleCreate";
import {ProductOrderTranslations} from "../products/ProductReorder";
import {CategoryOrderTranslations} from "../categories/CategoryReorder";
import {OrderUpdateTranslations} from "../products/UpdateOrder";

export default {
    ...raTr,
    login: {
        loginFailed: "Giriş başarısız. Kullanıcı adını ve şifreyi kontrol ediniz"
    },
    resources: {
        ...UserTranslations.tr,
        ...CategoryTranslations.tr,
        ...DepartmentTranslations.tr,
        ...CityTranslations.tr,
        ...DistrictTranslations.tr,
        ...TownTranslations.tr,
        ...DriverTranslations.tr,
        ...ProductTranslations.tr,
        ...CampaignTranslations.tr,
        ...OrderTranslations.tr,
        ...OrderReportTranslations.tr,
        ...AddressTranslations.tr,
        ...NotificationEmailTranslations.tr,
        ...NotificationTranslations.tr,
        ...ConfigurationStrings.tr,
        ...SaleTranslations.tr,
        ...RefundTranslations.tr,
        ...SaleReportTranslations.tr,
        ...DepoSaleUserTranslations.tr,
        ...SaleUserTranslations.tr,
        ...ShopSaleUserTranslations.tr,
        ...FaqTranslations.tr,
    },
    ...UserResetTranslations.tr,
    ...MenuTranslations.tr,
    ...PendingOrderTranslations.tr,
    ...DeliverOrderTranslations.tr,
    ...MarkOutOfStockTranslations.tr,
    ...ProductsListActionTranslations.tr,
    ...ImageUploadTranslation.tr,
    ...DepartmentOrderTranslations.tr,
    ...SaleCreateTranslations.tr,
    ...ProductOrderTranslations.tr,
    ...CategoryOrderTranslations.tr,
    ...OrderUpdateTranslations.en,
}
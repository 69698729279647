import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    Card,
    CardActions,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    MuiThemeProvider,
    Snackbar,
    TextField
} from "@material-ui/core";
import {Title, useTranslate} from "react-admin";
import CardContent from "@material-ui/core/CardContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {SERVER_IP} from "../index";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import {createMuiTheme} from "@material-ui/core/styles";
import {lightTheme} from "../layout/Layout";
import DeleteIcon from '@material-ui/icons/Delete';
import {isNumber} from "../Util";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 10,
        userSelect: 'none',
        borderRadius: 10,
    },
    table: {
        minWidth: 650,
    },
    saleButton: {
        width: '10em',
        borderRadius: '2em',
        marginLeft: "auto",
    },
    anotherButton: {
        marginLeft: 0,
        marginRight: '10em',
    },
    deleteButton: {
        color: 'red'
    },
    bigMoney: {
        fontWeight: "bold",
        fontSize: '20px'
    }
}));

const defaultObj = () => ({id: -1, barcode: "", name: "", itemPrice: 0, quantity: 1, stock: ""})

function ccyFormat(num, allowNegative) {
    if (isNumber(num) && allowNegative) {
        return `${num.toFixed(2)}`;
    }
    return num <= 0 ? "" : `${num.toFixed(2)}`;
}


function priceRow(qty, unit) {
    return ccyFormat(qty * unit);
}

function InternalSaleCreate({refund, depo}) {
    const classes = useStyles();
    const [rows, setRows] = useState([defaultObj()])
    const [change, setChange] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [discount, setDiscount] = useState(-1)
    const [award, setAward] = useState(-1)
    const [success, setSuccess] = useState(-1)
    const [delivery, setDelivery] = useState(-1)
    const [cashGiven, setCashGiven] = useState(-1)
    const translate = useTranslate()

    const onChange = (index, name, event) => {
        let row = rows[index]
        let value = event.target.value
        if (name === 'quantity') {
            if (value.length > 0 && !isNumber(value)) {
                return
            }
            if (Number(value) > row.stock && !refund) {
                return;
            }
        }
        row[name] = value
        rows[index] = row
        setRows(rows)
        setChange(!change)
    }

    const printReceipt = () => {
        let token = localStorage.getItem("token");
        let path;
        if (depo) {
            path = refund ? "depoRefunds" : "depoSales"
        } else {
            path = refund ? "refunds" : "sales"
        }
        window.open(`${SERVER_IP}/${path}/receipt/${success}?token=${token}`,
            "_blank")
        createOtherSale()
    }

    const createOtherSale = () => {
        setSuccess(-1)
        setRows([defaultObj()])
        setDiscount(0)
    }

    const onDiscountChange = event => {
        let value = Number(event.target.value)
        if (isNaN(value)) {
            return
        }
        setDiscount(value)
    }
    const onAwardChange = event => {
        let value = Number(event.target.value)
        if (isNaN(value)) {
            return
        }
        setAward(value)
    }
    const onDeliveryChange = event => {
        let value = Number(event.target.value)
        if (isNaN(value)) {
            return
        }
        setDelivery(value)
    }
    const onGivenCashChange = event => {
        let value = Number(event.target.value)
        if (isNaN(value)) {
            return
        }
        setCashGiven(value)
    }
    const calculateDiscount = () => {
        if (discount <= 0) {
            return 0
        }
        let sub = calculateSubTotal()
        if (sub === 0) {
            return 0;
        }
        return (discount / 100) * sub
    }


    const calculateSubTotal = () => {
        let total = 0;
        rows.forEach(item => {
            let price = item.itemPrice
            if (item.manualPrice && item.manualPrice > 0) {
                total += Number(item.manualPrice);
            } else {
                total += item.quantity * price
            }
        })
        return total
    }

    const calculateTotal = () => {
        let d = delivery > 0 ? delivery : 0;
        let a = award > 0 ? award : 0;
        return calculateSubTotal() - calculateDiscount() + d - a
    }
    const calculateCashBack = () => {
        let total = calculateTotal()
        let d = cashGiven > 0 ? cashGiven : 0;
        let a = total > 0 ? total : 0;
        return d - a
    }

    const onKeyPress = (index, name, e) => {
        if (e.key === "Enter") {
            if (name === 'barcode' || name === "id") {
                loadProduct(index, e.target.value, name === "id")
            } else if (name === 'quantity') {
                if (rows[index].quantity > 0) {
                    addRow(index)
                    focus(`barcode${index + 1}`)
                }
            } else if (name === 'manualPrice') {
                if (rows.length - 1 === index) {
                    addRow(index)
                }
                focus(`barcode${index + 1}`)
            }
            e.preventDefault()
        }
    }
    const focus = (name, timeout = 200) => {
        setTimeout(() => {
            const nextSibling = document.querySelector(
                `input[name=${name}]`
            );
            if (nextSibling != null) {
                nextSibling.focus()
            }
        }, timeout)
    }
    const addRow = (index) => {
        if (index < rows.length - 1) {
            return;
        }
        rows.push(defaultObj())
        setRows(rows)
        setChange(!change)
    }

    const loadProduct = (index, barcode, id = false) => {
        let alreadyPresent = rows.findIndex(r => r.barcode === barcode && r.id !== -1)
        if (alreadyPresent > -1) {
            let row = rows[alreadyPresent]
            row.quantity += 1
            rows[alreadyPresent] = row
            setChange(!change)
            focus(`quantity${alreadyPresent}`)
            return
        }
        setLoading(true)
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        fetch(`${SERVER_IP}/products/${id ? "searchWithId" : "searchWithBarcode"}/${barcode}`,
            {method: "GET", headers: headers})
            .then(response => {
                return response.json();
            })
            .then((product) => {
                setLoading(false);
                setupProduct(index, product)
            })
            .catch(e => {
                setMessage(translate('saleCreate.notFound'))
                setLoading(false);
            });
    }

    const setupProduct = (index, product) => {
        let alreadyPresent = rows.findIndex(r => r.id === product.id)
        if (alreadyPresent > -1) {
            let row = rows[alreadyPresent]
            index = alreadyPresent
            row.quantity += 1
            rows[index] = row
        } else {
            let row = rows[index]
            row.barcode = product.barcode
            row.id = product.id
            row.name = product.name.turkish
            row.stock = product.stock
            row.quantity = row.stock > 0 ? 1 : 0
            row.itemPrice = product.newPrice != null && product.newPrice > 0 ? product.newPrice : product.price
            rows[index] = row
        }
        setChange(!change)
        focus(`quantity${index}`)
    }

    const saveSale = () => {
        if (discount === -1) {
            setMessage(translate('saleCreate.messageDiscount'))
            return;
        }

        if (award === -1) {
            setMessage(translate('saleCreate.messageAward'))
            return;
        }

        if (delivery === -1) {
            setMessage(translate('saleCreate.messageDelivery'))
            return;
        }

        let sale = {
            discount: discount > 0 ? discount : null,
            delivery: delivery,
            award: award > 0 ? award : null,
            items: rows.filter(item => item.id !== -1 && item.quantity > 0)
                .map(row => ({
                    product: row.id,
                    quantity: row.quantity,
                    manualPrice: row.manualPrice && row.manualPrice > 0 ? row.manualPrice : null
                }))
        }
        if (sale.items.length === 0) {
            return
        }
        setLoading(true)
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        let path;
        if (depo) {
            path = refund ? "depoRefunds" : "depoSales"
        } else {
            path = refund ? "refunds" : "sales"
        }
        fetch(`${SERVER_IP}/${path}/create`,
            {method: "POST", headers: headers, body: JSON.stringify(sale)})
            .then(response => {
                return response.json();
            })
            .then((s) => {
                setSuccess(s.id)
                setLoading(false);
            })
            .catch(e => {
                setMessage(translate('saleCreate.fail'))
                setLoading(false);
            });
    }
    const onDeleteRow = (index) => {
        rows.splice(index, 1)
        setRows(rows)
        setChange(!change)
        if (rows.length === 0) {
            addRow(0)
        }
    }

    return (
        <Card>
            <Title title={refund ? translate('saleCreate.refundTitle') : translate('saleCreate.title')}/>
            <CardHeader>
                {loading && <CircularProgress size={24}/>}
            </CardHeader>
            <CardContent>
                <Table className={classes.table} aria-label="sale table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>#</b></TableCell>
                            <TableCell align="center"><b>{translate('saleCreate.barcode')}</b></TableCell>
                            <TableCell align="center"><b>{translate('saleCreate.id')}</b></TableCell>
                            <TableCell align="center"><b>{translate('saleCreate.product')}</b></TableCell>
                            <TableCell align="center"><b>{translate('saleCreate.stock')}</b></TableCell>
                            <TableCell align="center"><b>{translate('saleCreate.quantity')}</b></TableCell>
                            <TableCell align="right"><b>{translate('saleCreate.itemPrice')}</b></TableCell>
                            <TableCell align="right"><b>{translate('saleCreate.manual')}</b></TableCell>
                            <TableCell align="right"><b>{translate('saleCreate.totalPrice')}</b></TableCell>
                            <TableCell align={'center'}><b>{translate('saleCreate.action')}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>

                                <TableCell align="center">
                                    <TextField
                                        disabled={loading}
                                        onKeyPress={e => onKeyPress(index, 'barcode', e)}
                                        size={'small'}
                                        label={translate('saleCreate.barcode')}
                                        value={row.barcode}
                                        onChange={e => onChange(index, 'barcode', e)}
                                        name={`barcode${index}`}
                                        variant={'outlined'}/>
                                </TableCell>
                                <TableCell align="center">
                                    <TextField
                                        disabled={loading}
                                        onKeyPress={e => onKeyPress(index, 'id', e)}
                                        size={'small'}
                                        label={translate('saleCreate.id')}
                                        value={row.id === -1 ? "" : row.id}
                                        onChange={e => onChange(index, 'id', e)}
                                        name={`id${index}`}
                                        variant={'outlined'}/>
                                </TableCell>
                                <TableCell component="th"
                                           align={'center'}
                                           scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align={'center'}>{row.stock}</TableCell>
                                <TableCell align="center">
                                    <TextField
                                        disabled={row.name === "" || loading || (row.stock === 0 && !refund)}
                                        onKeyPress={e => onKeyPress(index, 'quantity', e)}
                                        size={'small'}
                                        required
                                        label={translate('saleCreate.quantity')}
                                        inputMode={'numeric'}
                                        name={`quantity${index}`}
                                        onChange={e => onChange(index, 'quantity', e)}
                                        value={row.quantity}
                                        variant={'outlined'}/>
                                </TableCell>
                                <TableCell align="right">{ccyFormat(row.itemPrice)}</TableCell>

                                <TableCell align="center">
                                    <TextField
                                        disabled={row.name === "" || loading || (row.stock === 0 && !refund)}
                                        onKeyPress={e => onKeyPress(index, 'manualPrice', e)}
                                        size={'small'}
                                        label={translate('saleCreate.manual')}
                                        inputMode={'numeric'}
                                        name={`manual${index}`}
                                        onChange={e => onChange(index, 'manualPrice', e)}
                                        value={row.manualPrice ? row.manualPrice : ""}
                                        variant={'outlined'}/>
                                </TableCell>

                                <TableCell
                                    align="right">{priceRow(row.manualPrice ? 1 : row.quantity,
                                    row.manualPrice && row.manualPrice > 0 ? row.manualPrice : row.itemPrice)}</TableCell>
                                <TableCell align={"center"}>
                                    {row.name !== "" &&
                                        <Button variant={"text"}
                                                startIcon={<DeleteIcon/>}
                                                className={classes.deleteButton}
                                                onClick={() => onDeleteRow(index)}
                                                color={'primary'}>
                                            {translate('saleCreate.delete')}
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell rowSpan={7} colSpan={7}/>
                            <TableCell colSpan={2}><b>{translate('saleCreate.subtotal')}</b></TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(calculateSubTotal())}</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><b>{translate('saleCreate.disc')}</b></TableCell>
                            <TableCell align="center">
                                <TextField
                                    disabled={loading}
                                    size={'small'}
                                    label={translate('saleCreate.discount')}
                                    inputMode={'numeric'}
                                    name={`discount`}
                                    required
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    onChange={onDiscountChange}
                                    value={discount === -1 ? "" : discount}
                                    variant={'outlined'}/>
                            </TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(calculateDiscount())}</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><b>{translate('saleCreate.award')}</b></TableCell>
                            <TableCell align="center">
                                <TextField
                                    disabled={loading}
                                    size={'small'}
                                    label={translate('saleCreate.award')}
                                    required
                                    inputMode={'numeric'}
                                    name={`award`}
                                    onChange={onAwardChange}
                                    value={award === -1 ? "" : award}
                                    variant={'outlined'}/>
                            </TableCell>
                            <TableCell align="right" className={classes.bigMoney}><b>{ccyFormat(award)}</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><b>{translate('saleCreate.deliveryFee')}</b></TableCell>
                            <TableCell align="center">
                                <TextField
                                    disabled={loading}
                                    size={'small'}
                                    required
                                    label={translate('saleCreate.deliveryFee')}
                                    inputMode={'numeric'}
                                    name={`deliveryFee`}
                                    onChange={onDeliveryChange}
                                    value={delivery === -1 ? "" : delivery}
                                    variant={'outlined'}/>
                            </TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(delivery)}</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}><b>{translate('saleCreate.total')}</b></TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(calculateTotal())}</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}><b>{translate('saleCreate.cashGiven')}</b></TableCell>
                            <TableCell align="right">
                                <TextField
                                    disabled={loading}
                                    size={'small'}
                                    label={translate('saleCreate.cashGiven')}
                                    inputMode={'numeric'}
                                    name={`cashGiven`}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    onChange={onGivenCashChange}
                                    value={cashGiven === -1 ? "" : cashGiven}
                                    variant={'outlined'}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}><b>{translate('saleCreate.cashBack')}</b></TableCell>
                            <TableCell align="right"
                                       className={classes.bigMoney}><b>{ccyFormat(calculateCashBack(), true)}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
            <Snackbar
                onClose={() => setMessage("")}
                autoHideDuration={3000}
                message={message}
                open={message.length > 0}/>
            <CardActions>
                <Button disabled={loading}
                        variant={'contained'}
                        color={'primary'}
                        size={"large"}
                        disableElevation={true}
                        className={classes.saleButton}
                        onClick={saveSale}>
                    {translate('saleCreate.save')}
                </Button>
            </CardActions>
            <Dialog open={success !== -1}>
                <DialogTitle>
                    {refund ? translate('saleCreate.refund') : translate('saleCreate.sale')} Saved
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {refund ? translate('saleCreate.refund') : translate('saleCreate.sale')} has been saved. Now you
                        can print receipt for this or you can
                        create another.
                        Do you want to print receipt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'primary'}
                        className={classes.anotherButton}
                        onClick={createOtherSale}>
                        {translate('saleCreate.another')} {refund ? translate('saleCreate.refund') : translate('saleCreate.sale')}
                    </Button>
                    <Button
                        onClick={() => setSuccess(-1)}>
                        {translate('saleCreate.cancel')}
                    </Button>
                    <Button onClick={printReceipt}
                            color={'secondary'}>
                        {translate('saleCreate.print')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

const SaleCreateTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <InternalSaleCreate {...props} />
    </MuiThemeProvider>
);

export const SaleCreate = ({resource}) => <SaleCreateTheme depo={resource === "depoSales"}/>
export const RefundCreate = ({resource}) => <SaleCreateTheme refund depo={resource === "depoRefunds"}/>

export const SaleCreateTranslations = {
    en: {
        saleCreate: {
            print: "Print Receipt",
            cancel: "Cancel",
            another: "Another ",
            success: " has been saved. Now you can print receipt for this or you can create another. Do you want to print receipt?",
            save: "Save",
            cashGiven: "Cash Given",
            cashBack: "Cash back (Change)",
            deliveryFee: "Delivery Fee",
            award: "Award",
            disc: "Discount",
            discount: "Discount %",
            subtotal: "Subtotal",
            delete: "Delete",
            manual: "Manual Price",
            quantity: "Quantity",
            id: "ID",
            barcode: "Barcode",
            product: "Product",
            stock: "Stock",
            itemPrice: "Item Price",
            totalPrice: "Total Price",
            action: "Actions",
            refund: "Refund",
            sale: "Sale",
            title: "Create Sale",
            refundTitle: "Create Refund",
            fail: "Failed to create.",
            messageAward: "Please enter award. You can enter 0",
            messageDiscount: "Please enter discount percentage. You can enter 0",
            messageDelivery: "Please enter delivery fee. You can enter 0",
            notFound: "Product not found.",
            total: "Total",
        }
    },
    tr: {
        saleCreate: {
            print: "Fiş yaz",
            cancel: "İptal",
            another: "Başka ",
            success: " has been saved. Now you can print receipt for this or you can create another. Do you want to print receipt?",
            save: "Kayıt",
            cashGiven: "Cash Given",
            cashBack: "Cash back (Change)",
            deliveryFee: "Delivery Fee",
            award: "Award",
            discount: "Discount %",
            subtotal: "Subtotal",
            delete: "Delete",
            manual: "Manual Price",
            quantity: "Quantity",
            id: "ID",
            disc: "Discount",
            barcode: "Barcode",
            product: "Product",
            stock: "Stock",
            itemPrice: "Item Price",
            totalPrice: "Total Price",
            action: "Actions",
            refund: "Refund",
            sale: "Sale",
            title: "Create Sale",
            refundTitle: "Create Refund",
            fail: "Failed to create.",
            messageAward: "Please enter award. You can enter 0",
            messageDiscount: "Please enter discount percentage. You can enter 0",
            messageDelivery: "Please enter delivery fee. You can enter 0",
            notFound: "Product not found.",
            total: "Total",
        }
    },
}
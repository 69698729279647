import React from "react";
import Icon from '@material-ui/icons/ShoppingBasket';
import {TimeField} from "../common/TimeField";
import {
    AutocompleteInput,
    Datagrid,
    Edit,
    EditButton,
    Filter,
    FormDataConsumer,
    List,
    ReferenceField,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    translate
} from 'react-admin';
import Basket from "./Basket";
import Chip from "@material-ui/core/Chip";
import {AddressField} from "../common/AddressField";
import OrderEditActions from "./OrderEditActions";
import {ListPagination} from "../common/Pagination";
import {EFaturaButton} from "../common/EFaturaButton";

export const OrderIcon = Icon;

const status = [
    {id: 'CONFIRMED', name: 'resources.orders.status.confirmed'},
    {id: 'CANCELLED', name: 'resources.orders.status.cancelled'},
]
const searchStatus = [
    {id: 0, name: 'resources.orders.status.pending'},
    {id: 1, name: 'resources.orders.status.confirmed'},
    {id: 2, name: 'resources.orders.status.delivered'},
    {id: 3, name: 'resources.orders.status.cancelled'},
]

const OrderFilters = (props) => (
    <Filter {...props}>
        <SelectInput source={'status'} choices={searchStatus} alwaysOn/>
        <ReferenceInput source="customer.id"
                        filterToQuery={phone => ({username: phone})}
                        reference={'customers'}>
            <AutocompleteInput source={'id'}
                               matchSuggestion={(filter, choice) => choice !== undefined &&
                                   choice.username !== undefined &&
                                   choice.username.toLowerCase().includes(filter.toLowerCase())}
            />
        </ReferenceInput>
    </Filter>
);

export const OrderList = props => (
    <List {...props} bulkActionButtons={false}
          sort={{field: 'pendingTime', order: 'DESC'}}
          pagination={<ListPagination/>}
          perPage={50}
          filters={<OrderFilters/>}>
        <OrderGrid/>
    </List>
)

export const OrderGrid = props => (
    <Datagrid {...props}>
        <TextField source={"id"}/>
        <TimeField source={"pendingTime"}/>
        <TextField source={"paymentType"}/>
        <ReferenceField source={'customer.id'} reference={'customers'} link={'show'}>
            <TextField source={'name'}/>
        </ReferenceField>
        <ItemsField source={'items'} addLabel={true} sortable={false}/>
        <StatusField source={"status"}/>
        <AddressField source={'address'}/>
        <TimeField source={"deliverTime"}/>
        <EFaturaButton/>
        <ShowButton/>
        <EditButton/>
    </Datagrid>
)

const ItemsField = ({record}) => {
    if (record === undefined || record.items === undefined) {
        return <span/>
    }
    return <ul style={{paddingLeft: '0'}}>
        {record.items.map(item => (
            <Chip key={item.product.id}
                  style={{marginRight: '5px'}}
                  label={`${item.quantity} x ${item.product.name.english}`}/>
        ))}
    </ul>
}
ItemsField.defaultProps = {
    addLabel: true
};

export const StatusField = translate(({record, source, translate}) => {
    if (record[source] === 0 || record[source] === undefined) {
        return <span>NA</span>
    } else {
        switch (record[source]) {
            case 'PENDING':
                return <span>{translate('resources.orders.status.pending')}</span>
            case 'DELIVERED':
                return <span>{translate('resources.orders.status.delivered')}</span>
            case 'CANCELLED':
                return <span>{translate('resources.orders.status.cancelled')}</span>
            default:
                return <span>{translate('resources.orders.status.confirmed')}</span>
        }
    }
});


export const OrderEdit = props => (
    <Edit {...props} aside={<Basket/>} actions={<OrderEditActions/>}>
        <SimpleForm toolbar={<OrderEditToolbar/>}>
            <TextInput source={'id'} disabled/>
            <TextInput source={"paymentType"} disabled/>

            <SelectInput source={'status'} choices={status}/>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if (formData.status === "CONFIRMED") {
                        return <ReferenceInput {...rest} source="driver.id"
                                               validate={required()}
                                               filterToQuery={text => ({name: text})}
                                               reference={'drivers'}>
                            <AutocompleteInput source={'id'}/>
                        </ReferenceInput>
                    } else if (formData.status === "CANCELLED") {
                        return <TextInput {...props} fullWidth source={'cancelReason'}/>
                    }
                }
                }
            </FormDataConsumer>
            <AddressField source={'address'}/>
        </SimpleForm>
    </Edit>
);

const OrderEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);


export const OrderTranslations = {
    en: {
        orders: {
            name: "Orders",
            status: {
                cancelled: "Cancelled",
                confirmed: "Confirmed",
                pending: "Pending",
                delivered: "Delivered"
            },
            fields: {
                id: "ID",
                pendingTime: "Order Time",
                customer: {
                    id: "Customer"
                },
                items: "Items",
                suitableTime: "Suitable Time",
                status: "Status",
                driver: {
                    id: "Driver"
                },
                basket: {
                    total: "Total",
                    quantity: "Quantity",
                    unitPrice: "Unit Price",
                    id: "ID",
                    name: "Name",
                }
            }
        }
    },
    tr: {
        orders: {
            name: "Siparişler",
            status: {
                cancelled: "İptal edildi",
                confirmed: "Onaylandı",
                pending: "Onay Bekliyor",
                delivered: "Teslim Edildi"
            },
            fields: {
                id: "ID",
                pendingTime: "Sipariş verilme zamanı",
                customer: {
                    id: "Müşteri"
                },
                items: "Ürünler",
                suitableTime: "teslimat istek  zamanı",
                status: "Durum",
                driver: {
                    id: "Sürücü"
                },
                basket: {
                    total: "Toplam",
                    quantity: "miktar",
                    unitPrice: "Birim Ücreti",
                    id: "ID",
                    name: "İsim",
                }
            }
        }
    }
}

import React from "react";

export const AddressField = ({record, source}) => {
    if (record[source] === 0 || record[source] === undefined) {
        return <span>NA</span>
    } else {
        let address = record[source];
        let string = address.comment + " " + address.town.name + " " +
            address.district.name + " " + address.city.name;
        return <span>{string}</span>;
    }
};
export const AddressString = address => (address.comment + " " + address.town.name + " " +
    address.district.name + " " + address.city.name);


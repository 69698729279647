import React from 'react';
import {translate} from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button'
import Dropzone from "react-dropzone";
import {SERVER_IP} from "../index";
import {fetchJson} from "../rest/fetch";


class ImageUpload extends React.Component {
    state = {
        file: null,
        uploading: false,
    };
    styles = {
        dropzone: {
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            background: "#d5d5d5",
            minHeight: 100,
            textAlign: 'center',
        },
        progress: {
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
            marginTop: 100,
        }
    };

    componentDidMount() {
        if (this.props.input.value != null && this.props.input.value.length > 5) {
            this.setState({file: this.props.input.value, selected: true})
        }
    }

    onDrop = (files) => {
        this.upload(files[0]);
    };

    upload = (file) => {
        this.setState({uploading: true});
        let formData = new FormData();
        formData.append('file', file);
        let url = SERVER_IP + "/upload/uploadImage";
        const token = localStorage.getItem('token');
        if (token == null) {
            return;
        }
        let requestHeaders = new Headers();
        requestHeaders.set('Authorization', `Bearer ${token}`);
        requestHeaders.append("Access-Control-Allow-Origin", "*");
        fetch(url, {headers: requestHeaders, body: formData, method: 'POST'})
            .then(response =>
                response.text().then(text => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                }))
            )
            .then(({body}) => {
                try {
                    let file = JSON.parse(body);
                    this.props.input.onChange(file.url);
                    this.setState({uploading: false, file: file.url})
                } catch (e) {
                }

            });
    };

    onRemove = () => {
        let fileUrl = this.state.file;
        this.props.input.onChange(null);
        this.setState({file: null});
        let url = `${SERVER_IP}/upload/delete?url=${encodeURI(fileUrl)}`;
        const token = localStorage.getItem('token');
        if (token == null) {
            return;
        }
        let requestHeaders = new Headers();
        requestHeaders.set('Authorization', `Bearer ${token}`);

        fetchJson(url, {headers: requestHeaders, method: "DELETE"})
            .then(res => {
                console.log(res);
            })
    };

    render() {
        const {translate} = this.props;
        if (this.state.file != null) {
            return <div style={this.styles.progress}>
                <span>
                <img src={this.state.file} height={400} width={400} alt=""/>
                <div>
                    <Button color={"primary"} onClick={this.onRemove.bind(this)}>
                        {translate("uploadImage.remove")}
                    </Button>
                </div>
                </span>
            </div>;
        } else if (this.state.uploading) {
            return <div style={this.styles.progress}>
                <CircularProgress size={60}/>
                <h2>{translate("uploadImage.uploading")}</h2>
            </div>
        }
        return <div style={this.styles.dropzone}>
            <Dropzone onDrop={this.onDrop.bind(this)}
                      multiple={false}
                      maxSize={1024 * 5 * 1024}
                      accept={"image/*"}>
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p style={{padding: "50px"}}>
                            {translate("uploadImage.selectMessage")} <b>{translate("uploadImage.max")} </b>
                        </p>
                    </div>
                )}
            </Dropzone>
        </div>
    }
}

export const ImageUploadTranslation = {
    en: {
        uploadImage: {
            selectMessage: "Drop image here, or click to select image",
            max: "5MB",
            uploading: "Uploading Image...",
            remove: "Remove",

        }
    },
    tr: {
        uploadImage: {
            selectMessage: "Resmi buraya bırakın veya resim seçmek için tıklayın",
            max: "5MB",
            uploading: "Resim Yükleniyor...",
            remove: "Sil",
        }
    }
};

export default translate(ImageUpload);
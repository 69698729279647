import React from "react";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {translate} from 'react-admin';
import classnames from 'classnames';

const useStyles = theme => ({
    container: {minWidth: '35em', marginLeft: '1em'},
    rightAlignedCell: {textAlign: 'right'},
    boldCell: {fontWeight: 'bold'},
});

class Basket extends React.Component {

    render() {
        const {classes, translate, record} = this.props;
        if (record === undefined) {
            return <span/>
        }
        return (

            <Paper className={classes.container} elevation={2}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {translate(
                                    'resources.orders.fields.basket.id'
                                )}
                            </TableCell>
                            <TableCell>
                                {translate(
                                    'resources.orders.fields.basket.name'
                                )}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {translate(
                                    'resources.orders.fields.basket.unitPrice'
                                )}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {translate(
                                    'resources.orders.fields.basket.quantity'
                                )}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {translate(
                                    'resources.orders.fields.basket.total'
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record.items.map(
                            (item) => (
                                <TableRow key={item.product.id}>
                                    <TableCell>
                                        {item.product.id}
                                    </TableCell>
                                    <TableCell>
                                        {item.product.name.english}
                                    </TableCell>
                                    <TableCell
                                        className={classes.rightAlignedCell}
                                    >
                                        {item.usedPrice.toLocaleString(undefined, {
                                            style: 'currency',
                                            currency: 'TRY',
                                        })}
                                    </TableCell>
                                    <TableCell
                                        className={classes.rightAlignedCell}
                                    >
                                        {item.quantity}
                                    </TableCell>
                                    <TableCell
                                        className={classes.rightAlignedCell}
                                    >
                                        {(
                                            item.usedPrice *
                                            item.quantity
                                        ).toLocaleString(undefined, {
                                            style: 'currency',
                                            currency: 'TRY',
                                        })}
                                    </TableCell>
                                </TableRow>
                            )
                        )}

                        <TableRow>
                            <TableCell colSpan={3}/>
                            <TableCell className={classes.boldCell}>
                                {translate(
                                    'resources.orders.fields.basket.total'
                                )}
                            </TableCell>
                            <TableCell
                                className={classnames(
                                    classes.boldCell,
                                    classes.rightAlignedCell
                                )}
                            >
                                {record.total.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'TRY',
                                })}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

export default translate(withStyles(useStyles)(Basket))
import * as React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {AddressString} from "../common/AddressField";
import Button from "@material-ui/core/Button";
import ShowIcon from '@material-ui/icons/Visibility';
import {Link} from 'react-router-dom';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {OrderConfirmButton} from "./OrderConfirmButton";
import EditIcon from '@material-ui/icons/Edit';

const columns = [
    {field: 'id', headerName: '#', width: 90},
    {field: 'customer', headerName: 'Customer', width: 150, valueGetter: (params => params.value.name)},
    {
        field: 'pendingTime', headerName: 'Order Time', width: 200,
        valueFormatter: (params => new Date(params.value).toLocaleString())
    },
    {field: 'status', headerName: 'Status', width: 150,},
    {
        field: 'driver', valueFormatter: driver => driver.value != null ? driver.value.name : "",
        headerName: 'Driver', width: 150,
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 300,
        valueGetter: (params) => AddressString(params.value)
    },
    {
        field: 'finalPrice',
        headerName: 'Amount',
        width: 150,
        valueFormatter: params => params.value.toLocaleString(undefined, {
            style: 'currency',
            currency: 'TRY',
        })
    },
    {
        field: 'paymentType',
        headerName: 'Payment Method',
        width: 200,
        valueFormatter: params => params.value ? params.value : ""
    },
    {
        field: "edit",
        disableColumnMenu: true,
        sortable: false,
        headerName: " ",
        width: 130,
        disableClickEventBubbling: true,
        renderCell: (params) => (
            <Button variant={'text'}
                    color={'primary'}
                    component={Link}
                    to={`/orderUpdate/${params.id}`}
                    startIcon={<EditIcon/>}>
                Edit
            </Button>

        ),
    },
    {
        field: "confirm",
        disableColumnMenu: true,
        sortable: false,
        headerName: " ",
        width: 130,
        disableClickEventBubbling: true,
        renderCell: ({row}) => (
            <OrderConfirmButton order={row}/>
        ),
    },
    {
        field: "receipt",
        disableColumnMenu: true,
        sortable: false,
        headerName: " ",
        width: 130,
        disableClickEventBubbling: true,
        renderCell: () => (
            <Button variant={'text'}
                    color={'primary'}
                    startIcon={<ReceiptIcon/>}>
                Receipt
            </Button>

        ),
    },

    {
        field: "show",
        disableColumnMenu: true,
        sortable: false,
        width: 130,
        headerName: " ",
        disableClickEventBubbling: true,
        renderCell: (params) => (
            <Button variant={'text'}
                    color={'secondary'}
                    startIcon={<ShowIcon/>}
                    component={Link}
                    to={`/orders/${params.id}`}>
                Show
            </Button>
        ),
    }


];


export default function OrderTable({rows, onReceiptClick, onConfirmClick, selectionModel, setSelectionModel}) {
    function currentlySelected(params) {
        const value = params.colDef.field;
        if (value === "receipt") {
            onReceiptClick(params.id)
        } else if (value === "confirm") {
            if (params.row.status === "PENDING") {
                onConfirmClick(params.id)
            }
        }
    }

    return (
        <div style={{height: 420, width: '100%'}}>
            <DataGrid rows={rows} columns={columns}
                      selectionModel={selectionModel}
                      onSelectionModelChange={(newSelection) => {
                          setSelectionModel(newSelection.selectionModel);
                      }}
                      onCellClick={currentlySelected}
                      pageSize={10} checkboxSelection/>
        </div>
    );
}

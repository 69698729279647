import React from 'react';

import englishMessages from './i18n/en';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {Admin, resolveBrowserLocale, Resource} from 'react-admin';
import AuthClient from "./login/AuthClient";
import {SERVER_IP} from "./index";
import OurClient from "./rest/OurClient";
import CustomLayout from "./layout/Layout";
import Login from "./login/Login"
import {CategoryCreate, CategoryEdit, CategoryList, CategoryShow} from "./categories/Category";
import {EditAddress, UserCreate, UserEdit, UserList, UserShow} from "./user/User";
import {DepartmentCreate, DepartmentEdit, DepartmentList, DepartmentShow} from "./categories/Department";
import {CityCreate, CityEdit, CityList} from "./address/City";
import {DistrictCreate, DistrictEdit, DistrictList} from "./address/District";
import {TownCreate, TownEdit, TownList} from "./address/Town";
import {DriverCreate, DriverEdit, DriverList, DriverShow} from "./user/Driver";
import {ProductCreate, ProductEdit, ProductList, ProductShow} from "./products/Product";
import {CampaignCreate, CampaignEdit, CampaignList, CampaignShow} from "./products/Campaign";
import Dashboard from "./dashboard/Dashboard";
import {OrderEdit, OrderList} from "./products/Order";
import {ListOrderReport} from "./report/OrderReport";
import {NotificationEmailCreate, NotificationEmailEdit, NotificationEmailList} from "./settings/NotificationEmail";
import {NotificationCreate, NotificationShow, NotificationsList} from "./notification/Notification";
import {ConfigurationEdit, ConfigurationsList} from "./settings/Configuration";
import routes from "./routes";
import {SaleList} from "./sale/SaleList";
import {RefundCreate, SaleCreate} from "./sale/SaleCreate";
import {RefundList} from "./sale/RefundList";
import {ListSaleReport} from "./report/ProductSaleReport";
import {SalePersonCreate, SalePersonEdit, SalePersonList, SalePersonShow} from "./user/SaleUser";
import {FaqCreate, FaqEdit, FaqList} from "./Faq";
import turkishMessages from './i18n/tr';

const messages = {
    en: {...englishMessages},
    tr: {...turkishMessages}
};
const i18nProvider = polyglotI18nProvider(() => messages[resolveBrowserLocale()], 'tr');

export const locale = () => {
    let loc = resolveBrowserLocale();
    if (loc !== 'tr' && loc !== 'en') {
        return 'tr';
    } else {
        return 'tr';
    }
};


function App() {
    return (
        <Admin dataProvider={OurClient(SERVER_IP)}
               authProvider={AuthClient}
               loginPage={Login}
               dashboard={Dashboard}
               customRoutes={routes}
               title={"Nerde Kaldı | Web Panel"}
               i18nProvider={i18nProvider}
               layout={CustomLayout}>
            {permissions => [
                permissions === "ROLE_ADMIN" && <Resource name={'departments'}
                                                          list={DepartmentList}
                                                          edit={DepartmentEdit}
                                                          show={DepartmentShow}
                                                          create={DepartmentCreate}/>,

                permissions === "ROLE_ADMIN" && <Resource name={'campaigns'}
                                                          list={CampaignList}
                                                          edit={CampaignEdit}
                                                          show={CampaignShow}
                                                          create={CampaignCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'cities'}
                                                          list={CityList}
                                                          edit={CityEdit}
                                                          create={CityCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'districts'}
                                                          list={DistrictList}
                                                          edit={DistrictEdit}
                                                          create={DistrictCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'towns'}
                                                          list={TownList}
                                                          edit={TownEdit}
                                                          create={TownCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'salePersons'}
                                                          list={SalePersonList}
                                                          edit={SalePersonEdit}
                                                          show={SalePersonShow}
                                                          create={SalePersonCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'faq'}
                                                          list={FaqList}
                                                          edit={FaqEdit}
                                                          create={FaqCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'depoSalePersons'}
                                                          list={SalePersonList}
                                                          edit={SalePersonEdit}
                                                          show={SalePersonShow}
                                                          create={SalePersonCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'shopSalePersons'}
                                                          list={SalePersonList}
                                                          edit={SalePersonEdit}
                                                          show={SalePersonShow}
                                                          create={SalePersonCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={"orderReports"}
                                                          list={ListOrderReport}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'notificationEmails'}
                                                          list={NotificationEmailList}
                                                          edit={NotificationEmailEdit}
                                                          create={NotificationEmailCreate}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'notifications'}
                                                          show={NotificationShow}
                                                          create={NotificationCreate}
                                                          list={NotificationsList}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'appConfigs'}
                                                          list={ConfigurationsList}
                                                          edit={ConfigurationEdit}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'saleReports/shop'}
                                                          list={ListSaleReport}/>,
                permissions === "ROLE_ADMIN" && <Resource name={'saleReports/depo'}
                                                          list={ListSaleReport}/>,
                (permissions === "ROLE_ADMIN" || permissions === "ROLE_SALE_PERSON" || permissions === "ROLE_SHOP_SALE_PERSON") &&
                <Resource name={'sales'}
                          list={SaleList}
                          create={SaleCreate}/>,
                (permissions === "ROLE_ADMIN" || permissions === "ROLE_SALE_PERSON" || permissions === "ROLE_DEPO_SALE_PERSON") &&
                <Resource name={'depoSales'}
                          list={SaleList}
                          create={SaleCreate}/>,
                (permissions === "ROLE_ADMIN" || permissions === "ROLE_SALE_PERSON") &&
                <Resource name={'refunds'}
                          list={RefundList}
                          create={RefundCreate}/>,
                (permissions === "ROLE_ADMIN" || permissions === "ROLE_SALE_PERSON") &&
                <Resource name={'depoRefunds'}
                          list={RefundList}
                          create={RefundCreate}/>,

                <Resource name={'customers'}
                          list={UserList}
                          edit={permissions === "ROLE_ADMIN" ? UserEdit : undefined}
                          show={UserShow}
                          create={permissions === "ROLE_ADMIN" ? UserCreate : undefined}/>,
                <Resource name={'drivers'}
                          list={DriverList}
                          edit={permissions === "ROLE_ADMIN" ? DriverEdit : undefined}
                          show={DriverShow}
                          create={permissions === "ROLE_ADMIN" ? DriverCreate : undefined}/>,
                <Resource name={'addresses'} edit={permissions === "ROLE_ADMIN" ? EditAddress : undefined}/>,
                <Resource name={'orders'}
                          list={OrderList}
                          edit={OrderEdit}/>,
                <Resource name={"categories"}
                          list={permissions === "ROLE_ADMIN" ? CategoryList : undefined}
                          edit={permissions === "ROLE_ADMIN" ? CategoryEdit : undefined}
                          show={permissions === "ROLE_ADMIN" ? CategoryShow : undefined}
                          create={permissions === "ROLE_ADMIN" ? CategoryCreate : undefined}/>,
                <Resource create={permissions === "ROLE_ADMIN" ? ProductCreate : undefined}
                          list={ProductList}
                          edit={permissions === "ROLE_ADMIN" ? ProductEdit : undefined}
                          show={ProductShow}
                          name={'products'}/>,
            ]
            }
        </Admin>
    );
}

export default App;
